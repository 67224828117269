import { authProvider } from "../auth";
import ExploPage from "@organisms/ExploPage";

function StudentData() {
  const embedId = authProvider.studentDataEmbedId || "ovARNGWAlV" // Data Portal v3. We do this just in case it's not set correctly

  return (
    <ExploPage embedId={embedId} />
  )
}

export default StudentData;
