import { authProvider } from "@/auth";
import { useEffect, useState } from "react";
import { Icons } from "@/components/icons";
import { authorizedBackendPost } from "@/lib/backend";

type Props = {
    embedId: string;
}

function ExploPage({ embedId }: Props) {
    const [jwt, setJwt] = useState<string | null>(null);

    useEffect(() => {
        async function fetchExploJwt() {
            if (embedId === "") {
                return;
            }
            const body = {
                "provided_id": authProvider.email,
                "embed_id": embedId
            }

            const results = await authorizedBackendPost("/api/generate_explo_jwt",
                body
            )
            setJwt(results.data.jwt)
        }
        fetchExploJwt()
    }, [embedId])

    if (!jwt && embedId !== "") {
        return <Icons.spinner className="m-auto h-4 w-4 animate-spin" />
    }


    return (
        <div className="w-full">
            {jwt && <explo-dashboard
                dash-jwt={jwt}
                isProduction={true}
                environment="production"
                refresh-minutes={10}
                style={{ height: "100vh", width: "100vw" }}
            >
            </explo-dashboard>
            }
            {!jwt && <div className="text-center text-gray-500 mt-12">There is no data to display at this time</div>}
        </div >
    )
}

export default ExploPage;

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'explo-dashboard': any;
        }
    }
}