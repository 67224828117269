import { authProvider } from "@/auth";
import { Permission, Role } from "@/lib/authz/authz_generated";
import { PropsWithChildren } from "react";

type Props = {
    requiredRoles?: Role[],
    requiredPermissions?: Permission[],
    requiredPredicate?: () => boolean
}

const DeniedView: React.FC = () => {
    return <div>You are not allowed to view this page</div>
}

const AuthGuard: React.FC<PropsWithChildren<Props>> = ({ children, requiredRoles, requiredPermissions, requiredPredicate }) => {
    if (!authProvider.isAuthenticated) {
        return <DeniedView />
    }

    if (requiredRoles) {
        if (!requiredRoles.some((role) => authProvider.roles.includes(role))) {
            return <DeniedView />
        }
    }

    if (requiredPermissions) {
        if (!requiredPermissions.some((permission) => authProvider.permissions.includes(permission))) {
            return <DeniedView />
        }
    }

    if (requiredPredicate && !requiredPredicate()) {
        return <DeniedView />
    }

    return children;
};

export default AuthGuard;