import { Day } from "@/lib/core/day";
import { FC, useCallback, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Checkbox, Textarea, NumberInput, TextInput } from "@mantine/core";
import useAssessmentData from "./UseAssessmentData";
import { useToast } from "../ui/use-toast";

export type FormProps = {
    sessionId: number;
    studentName: string;
    roomName: string;
    studentId: string;
    administrationDay: Day;
    cycle: number;
    videoDay: Day;
    videoUrl: string;

    onSubmit: (data: SubmissionData) => void;
    onError: (error: string) => void;
}



type FormData = {
    numberOfErrors?: number;
    totalWords?: number;
    valid?: boolean;
    notes?: string;
}

export type SubmissionData = FormData & Omit<FormProps, "onSubmit" | "studentName" | "onError">;

const SubmissionForm: FC<FormProps & FormData> = ({ sessionId, studentName, roomName, studentId, administrationDay, cycle, videoDay, videoUrl, onSubmit, onError }) => {
    const { toast } = useToast();
    const [isSaving, setIsSaving] = useState(false);

    const { query, upsertAssessmentData } = useAssessmentData(studentId, sessionId, (error) => {
        toast({
            title: "Unable to save assessment",
            description: error,
            variant: "destructive",
        });
        onError(error);
    });
    const initialFormData: FormData = useMemo(() => {
        return query.data ?? {};
    }, [query.data]);
    const [formData, setFormData] = useState<FormData>(initialFormData);

    const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSaving(true);
        await upsertAssessmentData.mutateAsync({ ...formData, studentId, administrationDay, cycle, videoDay, videoUrl, roomName, sessionId });
        onSubmit({ ...formData, studentId, administrationDay, cycle, videoDay, videoUrl, roomName, sessionId });
        setIsSaving(false);
    }, [formData, studentId, administrationDay, cycle, videoDay, videoUrl, roomName, onSubmit]);

    if (query.isLoading) {
        return <div>Loading...</div>;
    }



    return <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div>
            <TextInput label="Student Name" id="studentName" value={studentName ?? ''} disabled />
        </div>
        <div>
            <TextInput label="Administration Day" id="administrationDay" value={administrationDay.toString() ?? ''} disabled />
        </div>
        <div>
            <TextInput label="Cycle" id="cycle" value={cycle.toString() ?? ''} disabled />
        </div>
        <div>
            <NumberInput label="Number of Errors" id="numberOfErrors" value={formData.numberOfErrors ?? 0} onChange={(e) => setFormData({ ...formData, numberOfErrors: parseInt((e.toString())) })} />
        </div>
        <div>
            <NumberInput label="Total Words" id="totalWords" value={formData.totalWords ?? 0} onChange={(e) => setFormData({ ...formData, totalWords: parseInt(e.toString()) })} />
        </div>
        <div className="flex items-center gap-2">
            <Checkbox label="Valid" id="valid" checked={formData.valid ?? true} onChange={(e) => setFormData({ ...formData, valid: e.target.checked })} />
        </div>
        <div>
            <Textarea label="Notes" id="notes" value={formData.notes ?? ''} onChange={(e) => setFormData({ ...formData, notes: e.target.value })} />
        </div>
        <Button type="submit" variant="outline" disabled={isSaving}>{isSaving ? "Saving..." : "Submit"}</Button>
    </form>;
};

export default SubmissionForm;
