import { FC } from "react";
import { Select } from "@mantine/core";
import { useSchoolYearsList } from "../hooks/useSchoolYear";
import StepPara from "../components/StepPara";

type Props = {
    selectedYear: number | null;
    onComplete: (year: number | null) => void;
}

const SelectYear: FC<Props> = ({ onComplete, selectedYear }) => {
    const { data: years, isLoading, isError } = useSchoolYearsList();

    return (
        <>
            {
                isLoading && (
                    <StepPara>
                        Loading...
                    </StepPara>
                )
            }
            {
                isError && (
                    <StepPara>
                        Error loading school years.
                    </StepPara>
                )
            }
            {
                years && (<>
                    <StepPara>
                        Select the school year you want to provision accounts for.
                    </StepPara>
                    <Select
                        label="Select School Year"
                        data={years?.map(year => ({ label: year.school_year_description, value: year.school_year_id.toString() })) ?? []}
                        value={selectedYear?.toString() ?? null}
                        onChange={(value) => onComplete(value ? parseInt(value) : null)}
                    />
                </>
                )
            }
        </>
    )
}

export default SelectYear;