import { Button } from "@/components/ui/button";
import StepPara from "../components/StepPara";
type Props = {
    enabled: boolean;
    selectedYear: number;
    selectedSchool: string;
    onComplete: (year: number, school: string) => void;
}

const DownloadCSV: React.FC<Props> = ({ enabled, selectedYear, selectedSchool, onComplete }) => {
    return <>
        <StepPara>This file will contain all of the students currently in the system.
            Use this to update the student roster. You can also add new students by adding new rows with the student_id column empty.
        </StepPara>
        <div className="flex gap-2">
            <Button variant="once" disabled={!enabled || !selectedYear || !selectedSchool}
                onClick={() => onComplete(selectedYear, selectedSchool)}>Download Student Roster CSV File</Button>
        </div>
    </>
};

export default DownloadCSV;