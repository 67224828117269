import { useState, useEffect, useMemo } from "react";
import SchoolSelection from "./SchoolSelection";
import DateRange from "@molecules/DateRange";
import LogTable from "./LogTable";
import DateNavigator from "@molecules/DateNavigator";
import { Day } from "@/lib/core/day";
import { useSchoolAssociations } from "@/hooks/useSchoolAssociations";
import { useInstructionalLog } from "@/hooks/useInstructionalLog";
import Loading from "../ui/loading";
import { useMaxCurriculum } from "@/hooks/useMaxCurriculum";
import { useSchoolsList } from "@/hooks/useSchoolsList";
import InstructorOutDialog from "./InstructorOutDialog";
import { InstructorDialogProvider, OutKind } from "./UseInstructorOutDialog";
import SavingIndicator from "@atoms/SavingIndicator";
import { useDistrictCalendar } from "@/hooks/useDistrictCalendar";
import { accessLevelForStudentAssignments, useStudentAssignmentsCapabilities } from "@/hooks/useStudentAssignments";
import { SelectedSchoolContext } from "@/contexts/SelectedSchool";
import { useQueryClient } from "@tanstack/react-query";
import { HardRefreshOverlay } from "@/hooks/useHardRefreshOverlay";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LocalStorageParams } from "@/main";
// TODO: Decompose into objects rather than everything at the top level
type Props = {
    selectedDate: Date;
    school: string | null;
    // Instructor/Student pairs
    setDate: (date: Date) => void;
}

type LoadableLogProps = {
    selectedSchool: string;
    startDate: Date;
    endDate: Date;
    holidays: Day[];
}

const Legend = () => {
    return (
        <div className="flex flex-row justify-around">
            <div>s = student absence</div>
            <div>i = instructor absence</div>
            <div>o = other responsibility</div>
        </div>
    )
}

const LoadableLog: React.FC<LoadableLogProps> = ({
    startDate,
    endDate,
    selectedSchool,
    holidays,
}) => {
    const { instructorAssignment, instructorOutForDay } = useFlags();
    const associations = useSchoolAssociations(selectedSchool);
    const { query: instructionalLog, updateInstructorStatusForDay, upsert: statusUpsert } = useInstructionalLog(selectedSchool, startDate);
    const latestCurriculumByStudent = useMaxCurriculum(selectedSchool);
    const studentAssignmentsCapabilities = useStudentAssignmentsCapabilities(selectedSchool);
    const studentAssignmentAccessLevel = useMemo(() => accessLevelForStudentAssignments(studentAssignmentsCapabilities?.data, undefined, selectedSchool), [
        studentAssignmentsCapabilities,
        selectedSchool,
    ]);
    const queryClient = useQueryClient();

    if (!associations.data) {
        return <div>
            <Loading {...associations} />
        </div>
    }

    if (!instructionalLog.data) {
        return <div>
            <Loading {...instructionalLog} />
        </div>
    }

    if (!latestCurriculumByStudent.data) {
        return <div>
            <Loading {...latestCurriculumByStudent} />
        </div>
    }

    return (
        <InstructorDialogProvider onSubmit={(day, id, v, kind, committer) => {
            if (v) {
                let value = undefined;
                if (kind === OutKind.Absent) {
                    value = "i";
                } else if (kind === OutKind.OtherResponsibilities) {
                    value = "o";
                }

                if (value) {
                    updateInstructorStatusForDay.mutate({
                        date: day,
                        instructor: id,
                        value,
                    })
                }
            } else {
                committer();
            }
        }}><SelectedSchoolContext.Provider value={selectedSchool}>
                {instructorOutForDay && <InstructorOutDialog />}
                <SavingIndicator isActive={Boolean(queryClient.isFetching()) || Boolean(queryClient.isMutating()) || instructionalLog.isRefetching || instructionalLog.isFetching || instructionalLog.isLoading} />
                <LogTable
                    upsert={statusUpsert}
                    latestCurriculumByStudent={latestCurriculumByStudent.data ?? new Map()}
                    startDate={Day.fromDate(startDate)}
                    endDate={Day.fromDate(endDate)}
                    holidays={holidays}
                    students={associations.data}
                    logData={instructionalLog.data}
                    studentAssignmentsAccessLevel={instructorAssignment ? studentAssignmentAccessLevel : undefined}
                    setLog={(_prev: any) => { }} />
            </SelectedSchoolContext.Provider>
        </InstructorDialogProvider>

    )
}

const Log: React.FC<Props> = ({ selectedDate, school, setDate }) => {
    const startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - 7);

    const [selectedSchool, setSelectedSchool] = useState(school);
    const noninstructionalDates = useDistrictCalendar(selectedSchool, startDate);
    const { data: holidays } = noninstructionalDates
    const schools = useSchoolsList();

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 13);

    const prevWeek = () => {
        const d = new Date(startDate);
        d.setDate(d.getDate() - 7);
        setDate(d);
    }
    const nextWeek = () => {
        const d = new Date(selectedDate);
        d.setDate(d.getDate() + 14);
        setDate(d);
    }

    useEffect(() => {
        if (schools.data && !selectedSchool) {
            const item = localStorage.getItem(LocalStorageParams.SelectedSchool);
            if (item) {
                setSelectedSchool(item);
            }
        }
    }, [schools.data, selectedSchool])

    useEffect(() => {
        if (schools.data && schools.data.schools.size === 1 && !selectedSchool) {
            const first_school = schools.data.schools.values().next().value;
            if (first_school) {
                setSelectedSchool(first_school.school_id);
            }
        }
    }, [schools.data, selectedSchool])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="flex flex-col gap-6">
            <div className="max-w-fit"><SchoolSelection schools={schools?.data} selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} /></div>
            <div className="flex flex-row gap-4 items-center">
                <DateRange startDate={selectedDate} setDate={setDate} />
                <div className="grow pr-2"><DateNavigator prevWeek={prevWeek} nextWeek={nextWeek} legend={<Legend />} /></div>
            </div>
            {selectedSchool &&
                <HardRefreshOverlay>
                    <LoadableLog
                        selectedSchool={selectedSchool}
                        startDate={startDate}
                        endDate={endDate}
                        holidays={holidays?.map((noninstructionalDate) => noninstructionalDate.date) || []} />
                </HardRefreshOverlay>
            }
        </div>
    )
}

export default Log;

