import { useState } from "react";
import AuthGuard from "@molecules/AuthGuard";
import DistrictSelect from "@/components/Admin/Vacations/DistrictSelect";
import SchoolYearSelect, { SchoolYearRange } from "@/components/Admin/Vacations/YearSelect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedBackendGet, authorizedBackendPost } from "@/lib/backend";
import Loading from "@/components/ui/loading";
import DateList from "@/components/Admin/Vacations/DateList";
import { NoninstructionalDate } from "@/components/Admin/Vacations/types";
import AddVacation from "@/components/Admin/Vacations/AddVacation";
import { Day } from "@/lib/core/day";
import { Permission } from "@/lib/authz/authz_generated";
import { authProvider } from "@/auth";

type QueryDataType = {
    results: NoninstructionalDate[],
    temporaryRange: [Day, Day] | null
}

const ProtectedView: React.FC = () => {
    const [districtId, setDistrictId] = useState<string | null>(null);
    const [schoolYear, setSchoolYear] = useState<SchoolYearRange | null>(null);
    const queryClient = useQueryClient();
    const writeable = authProvider.hasPermission(Permission.CanWriteVacationDates);
    const query = useQuery({
        queryKey: ["vacation_dates", districtId],
        queryFn: async () => {
            if (!districtId) {
                return null;
            }

            const url = `/calendar/${districtId}/noninstructional`;
            const { data } = await authorizedBackendGet<{ dates: NoninstructionalDate[] }>(url);

            const results = data.dates.map((d) => {
                const day = Day.fromString(d.date as unknown as string);
                return {
                    ...d,
                    date: day,
                }
            });

            return {
                results,
                temporaryRange: null
            }
        },
        enabled: !!districtId,
    })

    const deleteMutation = useMutation({
        mutationKey: ["vacation_dates", "delete", districtId!],
        mutationFn: async (ids: number[]) => {
            const url = `/calendar/${districtId}/noninstructional`;
            const body: { ids: number[], action: "DELETE" } = {
                ids,
                action: "DELETE",
            }
            await authorizedBackendPost(url, body);
        },
        onMutate: async (ids: number[]) => {
            await queryClient.cancelQueries({ queryKey: ["vacation_dates", districtId!] });
            const previousDates = queryClient.getQueryData<QueryDataType>(["vacation_dates", districtId]);
            const idSet = new Set(ids);
            if (previousDates) {
                queryClient.setQueryData<QueryDataType>(["vacation_dates", districtId], {
                    results: previousDates.results.filter((date) => !idSet.has(date.id)),
                    temporaryRange: previousDates.temporaryRange
                });
            }
            return { previousDates };
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["vacation_dates", districtId!] });
            queryClient.invalidateQueries({ queryKey: ["DistrictCalendar"], exact: false });
        }
    })

    const addMutation = useMutation({
        mutationKey: ["vacation_dates", "add", districtId!],
        mutationFn: async (dateRange: [Day, Day]) => {
            const url = `/calendar/${districtId}/noninstructional`;
            const body = {
                start_date: dateRange[0],
                end_date: dateRange[1],
                action: "ADD",
            }
            await authorizedBackendPost(url, body);
        },
        onMutate: async (dateRange: [Day, Day]) => {
            // TODO: Implement updating with the full range
            await queryClient.cancelQueries({ queryKey: ["vacation_dates", districtId!] });
            const previousDates = queryClient.getQueryData<QueryDataType>(["vacation_dates", districtId]);
            if (previousDates) {
                queryClient.setQueryData<QueryDataType>(["vacation_dates", districtId], {
                    results: previousDates.results,
                    temporaryRange: dateRange
                });
            }
            return { previousDates };
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["vacation_dates", districtId!] });
            queryClient.invalidateQueries({ queryKey: ["DistrictCalendar"], exact: false });
        }
    })

    return <div className="p-8">
        <div className="flex flex-row gap-4 items-center"><DistrictSelect onSelect={(districtId: string) => {
            setDistrictId(districtId);
            setSchoolYear(null);
        }} selectedDistrictId={districtId} />
            <SchoolYearSelect onSelect={(school_year: SchoolYearRange | null) => {
                setSchoolYear(school_year);
            }} districtId={districtId} selectedYear={schoolYear} />
        </div>
        {districtId && <Loading {...query} />}
        {schoolYear && writeable && <AddVacation allowedRange={[schoolYear.start_date, schoolYear.end_date]} onSubmit={(dateRange: [Day, Day]) => {
            addMutation.mutate(dateRange);
        }} />}
        {query.data && schoolYear && <DateList writeable={writeable} days={query.data?.results.filter((d) => {
            const date = d.date.asDate();
            const startDate = new Date(schoolYear.start_date);
            const endDate = new Date(schoolYear.end_date);
            return date >= startDate && date <= endDate;
        })} doDelete={(ids) => {
            deleteMutation.mutate(ids);
        }} temporaryRange={query.data.temporaryRange ?? undefined} />}
    </div>
}

const VacationDates: React.FC = () => {
    return <AuthGuard requiredPermissions={[Permission.CanViewVacationDates]}><ProtectedView /></AuthGuard>
};

export default VacationDates;