import { Cross2Icon } from "@radix-ui/react-icons"
import { Button } from "./ui/button";
import { useCallback, useEffect, useRef } from "react";
interface DialogProps {
  children: React.ReactNode;
  id: string;
  title: string;
  showCloseButton?: boolean;
  onEscape?: () => void;
}

export function openDialog(id: string) {
  const dialog = document.querySelector(`dialog#${id}`) as HTMLDialogElement;
  dialog?.showModal();
}

export function closeDialog(id: string) {
  const dialog = document.querySelector(`dialog#${id}`) as HTMLDialogElement;
  dialog?.close();
}

const Dialog = (props: DialogProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const onClick = useCallback((event: MouseEvent) => {
    if (!dialogRef.current?.open) {
      return;
    }
    const clickOnDialogFull = dialogRef.current && dialogRef.current.contains(event?.target as Node);
    const clickOnDialogBox = boxRef.current && boxRef.current.contains(event?.target as Node);
    if (clickOnDialogFull && !clickOnDialogBox) {
      closeDialog(props.id);

      event.preventDefault();
    }
  }, [props.onEscape, props.id]);

  useEffect(() => {
    document.addEventListener("mousedown", onClick);
    return () => {
      document.removeEventListener("mousedown", onClick);
    }
  }, [onClick]);

  return (
    <dialog ref={dialogRef} id={props.id} className="p-3 rounded-md" onKeyDown={(e) => {
      if (e.key === "Escape" && props.onEscape) {
        props.onEscape();
      }
    }}>
      <div ref={boxRef}>
        <header className="p-2 flex items-center">
          <h2 className="text-lg font-semibold">{props.title}</h2>
          {props.showCloseButton && <Button
            onClick={() => closeDialog(props.id)}
            variant="outline"
            className="flex items-center justify-center ml-auto">
            <Cross2Icon />
          </Button>}
        </header>
        <div className="p-2">
          {props.children}
        </div>
      </div>
    </dialog>
  );
}

export default Dialog;