
// This file is automatically generated based on the data in the database.
// You should probably ask Grayson to automatically generate this file when
// there are updates to any of the tables that are used to generate this file.

export enum Permission {
    RequiresOAuth = "RequiresOAuth", // 10
    CanViewInstructionalLog = "CanViewInstructionalLog", // 100
    CanWriteInstructionalLog = "CanWriteInstructionalLog", // 101
    CanAssignCoaches = "CanAssignCoaches", // 110
    UsesNativeInstructionalLog = "UsesNativeInstructionalLog", // 199
    CanViewStudentData = "CanViewStudentData", // 200
    CanDownloadStudentData = "CanDownloadStudentData", // 210
    CanViewCoachData = "CanViewCoachData", // 300
    CanViewInstructorObjectives = "CanViewInstructorObjectives", // 400
    CanViewInstructorObjectivesCombined = "CanViewInstructorObjectivesCombined", // 410
    CanViewRecordings = "CanViewRecordings", // 500
    CanWriteReadingAssessment = "CanWriteReadingAssessment", // 501
    CanViewCurriculum = "CanViewCurriculum", // 600
    CanViewInstructorScoringForm = "CanViewInstructorScoringForm", // 700
    CanWriteInstructorScoringForm = "CanWriteInstructorScoringForm", // 701
    CanViewCoachMeetingForm = "CanViewCoachMeetingForm", // 800
    CanWriteCoachMeetingForm = "CanWriteCoachMeetingForm", // 801
    CanViewCoachManagement = "CanViewCoachManagement", // 900
    CanWriteCoachManagementPd = "CanWriteCoachManagementPd", // 901
    CanWriteCoachManagementMgmt = "CanWriteCoachManagementMgmt", // 902
    CanWriteCoachManagementOther = "CanWriteCoachManagementOther", // 903
    CanViewVacationDates = "CanViewVacationDates", // 1000
    CanWriteVacationDates = "CanWriteVacationDates", // 1001
    CanViewReadingAssessorData = "CanViewReadingAssessorData", // 1100
    CanAssignInstructorToStudent = "CanAssignInstructorToStudent", // 1200
    CanViewRosterUpload = "CanViewRosterUpload", // 1300
    CanViewDebugTools = "CanViewDebugTools", // 1400
    CanProvisionAccounts = "CanProvisionAccounts", // 1500
};

export enum Role {
    Instructor = "Instructor", // 10
    GlobalLogEdit = "Global Log Edit", // 20
    RegularCoachPayroll = "Regular Coach Payroll", // 30
    SpecialCoachPayroll = "Special Coach Payroll", // 40
    VacationCalendarEdit = "Vacation Calendar Edit", // 50
    Coach = "Coach", // 100
    Assessor = "Assessor", // 200
    DistrictAdministrator = "District Administrator", // 300
    SchoolAdministrator = "School Administrator", // 400
    SchoolSupportStaff = "School Support Staff", // 600
    SchoolClassroomTeacher = "School Classroom Teacher", // 700
    DirectToConsumer = "Direct To Consumer", // 800
    Corporate = "Corporate", // 32000
};

export enum Entitlement {
    Instructor = "Instructor", // 10
    GlobalLogEdit = "Global Log Edit", // 20
    RegularCoachPayroll = "Regular Coach Payroll", // 30
    SpecialCoachPayroll = "Special Coach Payroll", // 40
    VacationCalendarEdit = "Vacation Calendar Edit", // 50
    CanUseNativeInstructionalLog = "Can Use Native Instructional Log", // 60
    AccountProvisioning = "Account Provisioning", // 70
    CoachAssignment = "Coach Assignment", // 80
    GraysonTestingEntitlement = "Grayson Testing Entitlement", // 32000
};
