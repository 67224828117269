import { Progress } from "@mantine/core";
import { FC } from "react";

type Props = {
    total: number;
    current: number;
}

const ProgressBar: FC<Props> = ({ total, current }) => {
    const progress = total > 0 ? Math.round((current / total) * 100) : 0;
    return (
        <Progress.Root transitionDuration={200} size={24}>
            <Progress.Section value={progress} withAria color="ponce">
                <Progress.Label>{progress}%</Progress.Label>
            </Progress.Section>
        </Progress.Root>
    )
}

export default ProgressBar;