import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type SchoolYearEntry = {
    school_year_id: number;
    school_year_description: string;
}

export type SchoolYearListOutput = SchoolYearEntry[];

const QUERY_KEY = ["provisioning", "years"];


const schoolYearsList = async (): Promise<SchoolYearListOutput> => {
    const url = `/admin/provisioning/years`;
    const { data } = await authorizedBackendGet<{ years: SchoolYearEntry[] }>(url);

    return data.years;
};

export const useSchoolYearsList = () => {
    return useQuery<SchoolYearListOutput, Error>({
        queryKey: QUERY_KEY,
        queryFn: () => schoolYearsList(),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false
    });
};
