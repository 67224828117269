import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/ui/select";
import { SelectedSchoolContext } from "@/contexts/SelectedSchool";
import { useInstructorsForSchool } from "@/hooks/useSchoolAssociations";
import { FC, useContext, useState } from "react";

type CommonProps = {
    instructorName: string;

}

type Props = CommonProps & {
    instructorId: string;
    studentId: string;
    canAssignInstructor: boolean;
};

const ReadOnlyInstructor: FC<CommonProps> = ({ instructorName }) => {
    return <>{instructorName}</>;
}

type SelectOption = {
    value: string;
    label: string;
}

const InstructorAssignmentControl: FC<Props> = (props) => {
    const currentSchoolId = useContext(SelectedSchoolContext);
    if (!currentSchoolId) {
        // This should never happen
        throw new Error("No school selected");
    }
    const { query, setInstructorForStudent } = useInstructorsForSchool(currentSchoolId);

    const [selectedInstructor, setSelectedInstructor] = useState<SelectOption | undefined>({ value: props.instructorId, label: props.instructorName });
    return <Select onValueChange={(value) => {
        console.log("Selected Instructor", value, "for student", props.studentId);
        if (value === "<<unassigned>>") {
            console.log("Unassigning instructor");
            setInstructorForStudent.mutate({ student_id: props.studentId, instructor_id: "<<unassigned>>" });
            setSelectedInstructor({ value: "<<unassigned>>", label: `UNASSIGNED` });
            return
        }
        const instructor = query.data?.filter(x => x.userId === value).at(0);
        if (!instructor) {
            console.error("Instructor not found");
            setSelectedInstructor(undefined);
            return;
        }

        setInstructorForStudent.mutate({ student_id: props.studentId, instructor_id: instructor.userId });
        setSelectedInstructor({ value: instructor.userId, label: `${instructor.firstName} ${instructor.lastName}` });
    }} value={selectedInstructor?.value}>
        <SelectTrigger>
            {selectedInstructor?.label}
        </SelectTrigger>
        <SelectContent>
            <SelectGroup>
                <SelectItem value="<<unassigned>>">UNASSIGNED</SelectItem>
                {query.data?.map((value) => {
                    return <SelectItem key={value.userId} value={value.userId}>{value.firstName} {value.lastName}</SelectItem>
                })}
            </SelectGroup>
        </SelectContent>
    </Select>
}

const InstructorAssignment: FC<Props> = (props) => {
    if (!props.canAssignInstructor) {
        return <ReadOnlyInstructor instructorName={props.instructorName} />;
    }

    return <InstructorAssignmentControl {...props} />;
};

export default InstructorAssignment;