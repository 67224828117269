import AuthGuard from "@molecules/AuthGuard";
import { authorizedDownload } from "@/lib/backend";
import UploadStepProcess from "@/components/Admin/Provisioning/UploadStepProcess";
import { useState } from "react";
import { Permission } from "@/lib/authz/authz_generated";


const ProtectedView: React.FC = () => {
    const [completed, setCompleted] = useState<string[]>([]);

    return <div className="flex flex-col gap-4 p-6">
        <h1 className="text-2xl font-bold">Account Provisioning</h1>
        <UploadStepProcess completed={completed}
            setCompleted={(value: string) => setCompleted([...completed, value])}
            resetCompleted={(value: string) => setCompleted([value])}
            onDownload={(year: number, schoolId: string, filename: string) => {
                const url = `/admin/provisioning/students/${year}/${schoolId}`;
                authorizedDownload(url, filename);
            }}
            onUpdate={() => {
                setCompleted([...completed, "update"]);
            }}
            onUpload={() => {
                setCompleted([...completed, "upload"]);
            }} />
    </div>
}

const AccountProvisioning: React.FC = () => {
    return <AuthGuard requiredPermissions={[Permission.CanProvisionAccounts]}><ProtectedView /></AuthGuard>
};

export default AccountProvisioning;