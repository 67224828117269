import { Button } from "@/components/ui/button";
import { FC } from "react";
import StepPara from "../components/StepPara";

type Props = {
    explanation: string | string[];
    isCompleted: boolean;
    onComplete: () => void;
    buttonText: string;
}



const BasicStep: FC<Props> = ({ explanation, isCompleted, onComplete, buttonText }) => {
    return (<>
        {Array.isArray(explanation) ? explanation.map((text, index) => <StepPara key={index}>{text}</StepPara>) : <StepPara>{explanation}</StepPara>}
        <Button variant="once" disabled={!isCompleted}
            onClick={() => onComplete()}>{buttonText}</Button></>)
}

export default BasicStep;