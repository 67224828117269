import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type SchoolEntry = {
    school_id: string;
    school_name: string;
    district_name: string;
}

export type SchoolListOutput = SchoolEntry[];

const QUERY_KEY = ["provisioning", "schools"];


const schoolList = async (year: number): Promise<SchoolListOutput> => {
    const url = `/admin/provisioning/schools/${year}`;
    const { data } = await authorizedBackendGet<{ schools: SchoolEntry[] }>(url);

    return data.schools;
};

export const useSchoolList = (year: number | null) => {
    return useQuery<SchoolListOutput, Error>({
        queryKey: QUERY_KEY.concat(year ? [year.toString()] : []),
        queryFn: () => schoolList(year!),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false,
        enabled: !!year
    });
};
