import SchoolSelection from "@/components/InstructionalLog/SchoolSelection";
import Loading from "@/components/ui/loading";
import { SelectedSchoolContext } from "@/contexts/SelectedSchool";
import { useMaxCurriculum } from "@/hooks/useMaxCurriculum";
import { useSchoolsList } from "@/hooks/useSchoolsList";
import { FC, Fragment, useState } from "react";

const MaxCurriculumDataList: FC<{ selectedSchool: string }> = ({ selectedSchool }) => {
    const latestCurriculumByStudent = useMaxCurriculum(selectedSchool);

    if (!latestCurriculumByStudent.data) {
        return <div>
            <Loading {...latestCurriculumByStudent} />
        </div>
    }

    return (
        <div>
            <h1 className="text-2xl font-bold">Curriculum Debug</h1>
            <div className="grid grid-cols-3 gap-3 my-6">
                <div className="font-bold border-b">Student</div>
                <div className="font-bold border-b">Max Curriculum</div>
                <div className="font-bold border-b">Entry Date</div>
                {latestCurriculumByStudent.data && [...latestCurriculumByStudent.data.entries()].map(([student, curriculum]) => {
                    return (
                        <Fragment key={student}>
                            <div>{curriculum.student_name}</div>
                            <div>{curriculum.value}</div>
                            <div>{curriculum.day.toString()}</div>
                        </Fragment>)
                        ;

                })}
            </div>
        </div>
    );
}

const Curriculum = () => {
    const [selectedSchool, setSelectedSchool] = useState<string | null>(null);
    const schools = useSchoolsList();

    if (!schools.data) {
        return <div>
            <Loading {...schools} />
        </div>
    }

    return (
        <div className="flex flex-col w-full p-3 gap-6">
            <div className="flex flex-col gap-6">
                <div className="max-w-fit"><SchoolSelection schools={schools?.data} selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} /></div>

                <SelectedSchoolContext.Provider value={selectedSchool ?? undefined}>
                    {selectedSchool && <MaxCurriculumDataList selectedSchool={selectedSchool} />}

                </SelectedSchoolContext.Provider>
            </div>
        </div>
    );
}

export default Curriculum;