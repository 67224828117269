import { FC, PropsWithChildren } from "react";
import { Alert } from '@mantine/core';
import { InfoCircledIcon, ExclamationTriangleIcon, CheckCircledIcon } from "@radix-ui/react-icons";
export enum NotificationKind {
    Error = "error",
    Success = "success",
    Warning = "warning",
    Info = "info",
}

type Props = {
    kind: NotificationKind;
    title: string;
    onClose: () => void;
}

const iconMap = {
    [NotificationKind.Error]: <ExclamationTriangleIcon />,
    [NotificationKind.Success]: <CheckCircledIcon />,
    [NotificationKind.Warning]: <ExclamationTriangleIcon />,
    [NotificationKind.Info]: <InfoCircledIcon />,
}

const colorMap = {
    [NotificationKind.Error]: "red",
    [NotificationKind.Success]: "green",
    [NotificationKind.Warning]: "yellow",
    [NotificationKind.Info]: "ponce",
}

const Notification: FC<PropsWithChildren<Props>> = ({ children, kind, title, onClose }) => {
    const icon = iconMap[kind] || <InfoCircledIcon />;
    const color = colorMap[kind] || "ponce";
    return (
        <div className="absolute top-6 right-6 z-50 max-w-prose">
            <Alert variant="filled" color={color} withCloseButton title={title} icon={icon} onClose={onClose}>
                {children}
            </Alert>
        </div>
    );
}


export default Notification;