import { Day } from "@/lib/core/day";
import AttendanceRow from "./AttendanceRow";
import LogRow from "./LogRow";
import { AverageDataMapProcessed } from "./utils";
import { SchoolAssociation } from "@/hooks/useSchoolAssociations";
import { InstructorToCoachMap } from "@/hooks/useSchoolAssociations";
import { InstructionalLogEntry } from "@/hooks/useInstructionalLog";
import useInstructorOutDialog, { OutKind } from "./UseInstructorOutDialog";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MaxCurriculumOutput } from "@/hooks/useMaxCurriculum";
import { useCallback } from "react";

type Props = {
    rowNumber: number;
    instructor: string;
    instructorName: string | undefined;
    dates: Day[];
    log: Map<string, Map<string, InstructionalLogEntry>>;
    students: Map<string, SchoolAssociation>
    curriculumByStudentMap: MaxCurriculumOutput;
    average: AverageDataMapProcessed;
    holidays: Set<string>,
    updateLog: (log_id: string | undefined, instructor: string, student: string, date: string, value: string, comment?: string) => void;
    commitChanges: (log_id: string | undefined, instructor: string, student: string, date: string, value: string, comment?: string) => void;
    today: string;
    writable: boolean;
    studentAssignmentsAccessLevel: string | undefined;
    coachAssignments: InstructorToCoachMap;
}

const InstructorSection: React.FC<Props> = ({
    rowNumber,
    instructor,
    students,
    dates,
    log,
    updateLog,
    commitChanges,
    curriculumByStudentMap,
    average,
    holidays,
    instructorName,
    today,
    writable,
    studentAssignmentsAccessLevel,
    coachAssignments
}) => {
    const { instructorOutForDay } = useFlags();
    const instructorOutDialog = useInstructorOutDialog();
    const fetchItems = useCallback((student_id: string) => {
        const logEntry = log?.get(student_id);
        const curriculum = curriculumByStudentMap?.get(student_id);
        const coachAssignment = coachAssignments.get(instructor);
        return {
            logEntry,
            curriculum,
            coachAssignment
        }
    }, [log, curriculumByStudentMap, coachAssignments, instructor]);
    return (
        <>
            <AttendanceRow today={today} rowTitle={`${instructorName} Avg`} dates={dates} log={average} holidays={holidays} />
            {
                [...(students.entries() ?? [])].map(([student_id, student], idx) => {
                    const { logEntry, curriculum, coachAssignment } = fetchItems(student_id);
                    return (
                        <LogRow editable={writable} key={`${coachAssignment?.coachId}-${instructor}-${student_id}`}
                            rowNumber={rowNumber * 1000 + idx}
                            instructor={instructorName ?? "unknown"}
                            coach={coachAssignment?.coachName}
                            coach_id={coachAssignment?.coachId}
                            coach_short_name={coachAssignment?.coachShortName}
                            instructor_id={instructor}
                            student={student.student_name}
                            student_id={student_id}
                            dates={dates}
                            holidays={holidays}
                            today={today}
                            maxCurriculum={curriculum?.value}
                            log={logEntry}
                            studentAssignmentsAccessLevel={studentAssignmentsAccessLevel}
                            updateLog={(date, log_id, value, comment) => { updateLog(log_id, instructor, student_id, date, value, comment); }}
                            commitChanges={(date, log_id, value, comment) => {
                                const commit = () => commitChanges(log_id, instructor, student_id, date, value, comment);
                                if (instructorOutForDay && value === 'o') {
                                    instructorOutDialog?.openDialog(Day.fromDate(new Date(date)), instructor, instructorName, OutKind.OtherResponsibilities, commit);
                                } else if (instructorOutForDay && value === 'i') {
                                    instructorOutDialog?.openDialog(Day.fromDate(new Date(date)), instructor, instructorName, OutKind.Absent, commit);
                                } else {
                                    commit();
                                }
                            }} />
                    );
                })
            }
        </>
    )
}

export default InstructorSection;