import { NavLink, NavLinkProps } from "react-router-dom";
import { authProvider } from "@/auth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Permission } from "@/lib/authz/authz_generated";

/**
 *
 * Main navigation menu on the left side.
 */

interface Tab {
  // The visible text for the tab.
  name: string;
  // The url path for the tab. Must be mapped in the router to work properly.
  pathname: string;
  // Check one, do they have this permission? If not, the tab will not be visible.  
  permission?: Permission;
  // Then, check another, do they have this flag? If not, the tab will not be visible.
  flag?: string;
  // Lastly, if set, this function will be called last to determine if the tab should be visible
  additional?: () => boolean;
  // Otherwise, the tab will be visible.
}

const TABS: Tab[] = [
  {
    name: "Instructional Log",
    pathname: "/instructional-log",
    permission: Permission.CanViewInstructionalLog,
  },
  {
    name: "Student Data",
    pathname: "/student-data",
    permission: Permission.CanViewStudentData,
  },
  {
    name: "Coach Data",
    pathname: "/meeting-report",
    permission: Permission.CanViewCoachData,
  },
  {
    name: "Reading Assessor Data",
    pathname: '/reading-report',
    permission: Permission.CanViewReadingAssessorData,
  },
  {
    name: "Instructor Objectives",
    pathname: "/instructor-objectives",
    permission: Permission.CanViewInstructorObjectives,
  },
  {
    name: "My Recordings",
    pathname: "/my-recordings",
    permission: Permission.CanViewRecordings,
  },
  {
    name: "Curriculum",
    pathname: "/curriculum",
    permission: Permission.CanViewCurriculum,
  },
  {
    name: "Instructor Scoring Form",
    pathname: "/instructor-scoring",
    permission: Permission.CanViewInstructorScoringForm,
  },
  {
    name: "Coach Meeting Form",
    pathname: "/coaching-meeting",
    permission: Permission.CanViewCoachMeetingForm,
  },
  {
    name: "Coach Management",
    pathname: "/coach-management",
    permission: Permission.CanViewCoachManagement,
  },
  {
    name: "Instructor Objectives Combined",
    pathname: "/instructor-objectives-combined",
    permission: Permission.CanViewInstructorObjectivesCombined,
  },
  {
    name: "Vacation Dates",
    pathname: "/admin/vacations",
    permission: Permission.CanViewVacationDates,
  },
  // {
  //   name: "Roster Upload",
  //   pathname: "/roster-upload",
  //   permission: Permission.CanViewRosterUpload,
  // },
  {
    name: "Account Provisioning",
    pathname: "/account-provisioning",
    permission: Permission.CanProvisionAccounts,
  },
  {
    name: "Tab 7",
    pathname: "/tab7",
    permission: Permission.CanViewInstructionalLog
  },
  {
    name: "Debug Tools",
    pathname: "/debug-tools",
    permission: Permission.CanViewDebugTools,
  }
]

function navigation(flags: any) {
  const nav = TABS.filter((tab) => {
    if (tab.permission && !authProvider.permissions.includes(tab.permission)) {
      return false;
    }
    if (tab.flag && !flags[tab.flag]) {
      return false;
    }

    if (tab.additional) {
      return tab.additional();
    }

    return true;
  });

  return nav;
}

function Nav() {
  let flags = useFlags();
  let nav = navigation(flags);

  return (
    <menu className="w-72 bg-ponce text-white flex flex-col">
      {nav.map((navItem) => {
        return (
          navItem && (
            <StyledNavLink
              key={navItem.pathname}
              to={{
                pathname: navItem.pathname,
                search: location.search,
              }}
            >
              {navItem.name}
            </StyledNavLink>
          )
        );
      })}
    </menu>
  );
}

export default Nav;

function StyledNavLink(props: NavLinkProps) {
  return (
    <NavLink
      className={({ isActive }) => {
        return `px-4 py-4 whitespace-nowrap ${isActive ? "bg-white text-blue-700 " : ""} `
      }}
      to={props.to} >{props.children}</NavLink>
  )
}