import ExploPage from "@organisms/ExploPage";
import { Permission } from "@/lib/authz/authz_generated";
import AuthGuard from "@molecules/AuthGuard";

const InstructorObjectivesCombined: React.FC = () => {

    let embedId = "9PY4BXv1M4";

    return (
        <AuthGuard requiredPermissions={[Permission.CanViewInstructorObjectivesCombined]}>
            <ExploPage embedId={embedId} />
        </AuthGuard>
    )
};

export default InstructorObjectivesCombined;