import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type Coach = {
    user_id: string;
    name: string;
    is_active: boolean;
};

const QUERY_KEY = ["Coaches"];

const fetchCoaches = async (): Promise<Coach[]> => {
    const { data } = await authorizedBackendGet<{ coaches: Coach[] }>("/coachesMetadata");
    return data.coaches.sort((a, b) => a.name.localeCompare(b.name));
};

export const useCoaches = () => {
    return useQuery<Coach[], Error>({
        queryKey: QUERY_KEY,
        queryFn: fetchCoaches,
        staleTime: 1000 * 60 * 60, // 1 hour
    });
};
