import { authProvider } from "@/auth";
import { COACH_MEETING_REPORT_DASHBOARD_ID_COACH, COACH_MEETING_REPORT_DASHBOARD_ID_EMPLOYEE } from "@/consts";
import ExploPage from "@organisms/ExploPage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Role, Permission } from "@/lib/authz/authz_generated";
import AuthGuard from "@molecules/AuthGuard";

const CoachMeetingReport: React.FC = () => {
    const { coachDataTab } = useFlags();

    let embedId = "";
    if (authProvider.hasRole(Role.Corporate)) {
        embedId = COACH_MEETING_REPORT_DASHBOARD_ID_EMPLOYEE;
    } else if (authProvider.hasRole(Role.Coach)) {
        embedId = COACH_MEETING_REPORT_DASHBOARD_ID_COACH;
    }

    return (
        <AuthGuard requiredPermissions={[Permission.CanViewCoachMeetingForm]} requiredPredicate={() => coachDataTab}>
            <ExploPage embedId={embedId} />
        </AuthGuard>
    )
};

export default CoachMeetingReport;