import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";
import { Day } from "@/lib/core/day";

type WireFormat = {
    id: number;
    date: string;
    district_id: number;
    school_id?: number;
}

export type NoninstructionalDate = {
    id: number;
    date: Day;
    district_id: number;
    school_id?: number;
}

const QUERY_KEY = ["DistrictCalendar"];

const districtCalendar = async (school_id: string, date: Date): Promise<NoninstructionalDate[]> => {
    const url = `/calendar/${school_id}/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const { data } = await authorizedBackendGet<{ dates: WireFormat[] }>(url);
    return data.dates.map(({ date, ...rest }) => {
        const day = Day.fromString(date);
        return ({ date: day, ...rest });
    });
};

export const useDistrictCalendar = (school_id: string | null, date: Date) => {
    return useQuery<NoninstructionalDate[], Error>({
        queryKey: QUERY_KEY.concat(school_id ?? "", date.toDateString()),
        queryFn: () => districtCalendar(school_id ?? "", date),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false,
        enabled: !!school_id,
    });
};
