import { Day } from "@/lib/core/day"
import { NoninstructionalDate } from "./types"
import { Icons } from "@/components/icons"

type Props = {
    days: NoninstructionalDate[],
    doDelete: (ids: number[]) => void,
    temporaryRange?: [Day, Day],
    writeable: boolean
}

type DateRowProp = {
    startDate: Day,
    endDate: Day,
    ids: number[],
    doDelete?: () => void,
    writeable: boolean
}

type DateRanges = Map<Day, { endDate: Day, ids: number[] }>

const DateRow: React.FC<DateRowProp> = ({ startDate, endDate, doDelete, writeable }) => {
    const normalizedStartDate = startDate.toString();
    const normalizedEndDate = endDate.toString();
    return (
        <div className="grid grid-cols-[1fr,2fr] justify-center items-center border m-2">
            <div className="flex flex-row nowrap">
                <div className="p-4 nowrap">{normalizedStartDate}</div>
                <div className="p-4"> - </div>
                <div className="p-4 nowrap">{normalizedEndDate}</div>
            </div>
            {writeable && <div className="p-4 text-2xl text-red-500">
                {doDelete && <><button onClick={() => doDelete?.()}>🅧</button></>}
                {!doDelete && <Icons.spinner className="animate-spin" />}
            </div>}
        </div>
    )
};

const DateList: React.FC<Props> = ({ days, doDelete, temporaryRange, writeable }) => {
    const dateRanges: DateRanges = new Map();
    days.reduce((acc: Day | undefined, day) => {
        if (!acc) {
            const normalizedDate = day.date?.clone();
            dateRanges.set(normalizedDate, { endDate: day.date.clone(), ids: [day.id] })
            return normalizedDate;
        } else {
            const range = dateRanges.get(acc);

            const normalizedDate = day.date.clone();
            if (range && normalizedDate > acc && normalizedDate.delta(acc) === range.ids.length) {
                range.endDate = normalizedDate;
                range.ids.push(day.id);
                return acc;
            } else {
                dateRanges.set(normalizedDate, { endDate: day.date.clone(), ids: [day.id] })
            }
            return normalizedDate;
        }
    }, undefined)
    return (
        <div className="">
            {temporaryRange && <DateRow startDate={temporaryRange[0]} writeable={writeable} endDate={temporaryRange[1]} ids={[-1]} />}
            {Array.from(dateRanges.entries()).map(([date, { endDate, ids }]) => {
                return <DateRow key={date.toString()} startDate={date} endDate={endDate} ids={ids} doDelete={() => {
                    doDelete(ids)
                }} writeable={writeable} />
            })}
        </div>
    )
}

export default DateList;