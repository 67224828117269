import Axios, { AxiosResponse } from "axios";

function initiateDownload(response: AxiosResponse, filename: string) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
}

function headers() {
    const common_headers = {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    }
    const impersonation = localStorage.getItem("impersonation");
    if (impersonation) {
        const impersonationData = JSON.parse(impersonation);
        if (impersonationData.hash) {
            return {
                ...common_headers,
                "x-impersonation-token": impersonationData.hash
            };
        } else {
            return common_headers;
        }
    } else {
        return common_headers;
    }
}

export function backendUrl(path: string) {
    return `${import.meta.env.VITE_BACKEND_URL}${path}`;
}

export async function unauthorizedGet<T>(path: string, params?: any) {
    return await Axios.get<T>(backendUrl(path), { params });
}

export async function authorizedBackendPost<T>(path: string, body: T) {
    const results = await Axios.post(
        backendUrl(path),
        body,
        {
            headers: headers()
        }
    );
    return results;
}

export async function authorizedBackendGet<T>(path: string, params?: any) {
    return await Axios.get<T>(backendUrl(path), {
        headers: headers(),
        params
    });
}

export async function authorizedBackendPut<T>(path: string, body: T) {
    return await Axios.put(
        backendUrl(path),
        body,
        {
            headers: headers()
        }
    );
}

export async function authorizedBackendDelete(path: string) {
    return await Axios.delete(
        backendUrl(path),
        {
            headers: headers()
        }
    );
}

export async function authorizedDownload(path: string, filename: string) {
    const response = await Axios.get(backendUrl(path), {
        headers: headers(),
        responseType: "blob"
    });
    initiateDownload(response, filename);
}

export async function authorizedUploadWithDownloadOnError(path: string, file: File, outputFilename: string, onProgress: (progress: number) => void) {
    const formData = new FormData();
    formData.append("file", file);
    try {
        const response = await Axios.post(backendUrl(path), formData, {
            headers: headers(),
            onUploadProgress: (progressEvent) => {
                const progress = progressEvent.total ? Math.round((progressEvent.loaded / progressEvent.total) * 100) : 0;
                onProgress(progress);
            },
            responseType: "blob"
        });
        return response;
    } catch (error: any) {
        if (error.status === 406) {
            initiateDownload(error.response, outputFilename);
            return error.response;
        } else {
            throw error;
        }
    }

}