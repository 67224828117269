import { authProvider } from "@/auth";
import { UNASSIGNED_ID, UNASSIGNED_NAME, useCoachAssignments, useCoaches } from "../../hooks/useSchoolAssociations";
import { Permission } from "@/lib/authz/authz_generated";
import { useContext, useState } from "react";
import { SelectedSchoolContext } from "@/contexts/SelectedSchool";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/ui/select";


type Props = {
    coachId: string,
    instructorId: string,
    coachName: string,
    coachShortName: string,
}

type SelectOption = {
    value: string;
    label: string;
    shortLabel: string;
}

const ReadOnlyCoachAssignment: React.FC<Pick<Props, "coachName">> = ({ coachName }) => {
    return (
        <>
            {coachName}
        </>
    )
}

const WriteableCoachAssignment: React.FC<Props> = (props) => {
    const currentSchoolId = useContext(SelectedSchoolContext);
    if (!currentSchoolId) {
        // This should never happen
        throw new Error("No school selected");
    }

    const { data: coaches } = useCoaches();
    const { setCoachForInstructor } = useCoachAssignments(currentSchoolId);
    const [selectedCoach, setSelectedCoach] = useState<SelectOption | undefined>({ value: props.coachId, label: props.coachName, shortLabel: props.coachShortName });

    return <Select onValueChange={(value) => {
        console.log("Selected Coach", value, "for instructor", props.instructorId);
        if (value === UNASSIGNED_ID) {
            console.log("Unassigning coach");
            setCoachForInstructor.mutate({ instructor_id: props.instructorId, coach_id: UNASSIGNED_ID });
            setSelectedCoach({ value: UNASSIGNED_ID, label: UNASSIGNED_NAME, shortLabel: UNASSIGNED_NAME });
        } else {
            console.log("Assigning coach", value);
            const coach = coaches?.find((coach) => coach.userId === value);
            if (!coach) {
                console.error("Coach not found");
                return;
            }
            setSelectedCoach({ value: coach.userId, label: coach.name, shortLabel: coach.shortName });
            setCoachForInstructor.mutate({ instructor_id: props.instructorId, coach_id: coach.userId });
        }
    }} value={selectedCoach?.value}>
        <SelectTrigger>
            {selectedCoach?.shortLabel}
        </SelectTrigger>
        <SelectContent>
            <SelectGroup>
                <SelectItem value={UNASSIGNED_ID}>{UNASSIGNED_NAME}</SelectItem>
                {coaches?.map((coach) => {
                    return <SelectItem key={`${coach.userId}-${props.instructorId}`} value={coach.userId}>{coach.name}</SelectItem>
                })}
            </SelectGroup>
        </SelectContent>
    </Select>
}



const CoachAssignment: React.FC<Pick<Props, "instructorId"> & Partial<Pick<Props, "coachId" | "coachName" | "coachShortName">>> = ({ coachId, instructorId, coachName, coachShortName }) => {
    const writable = authProvider.hasPermission(Permission.CanAssignCoaches) && instructorId !== UNASSIGNED_ID;
    return (
        <>
            {writable ? (
                <WriteableCoachAssignment coachName={coachName ?? UNASSIGNED_NAME} coachId={coachId ?? UNASSIGNED_ID} instructorId={instructorId} coachShortName={coachShortName ?? UNASSIGNED_NAME} />
            ) : (
                <ReadOnlyCoachAssignment coachName={coachName ?? UNASSIGNED_NAME} />
            )}
        </>
    )
}

export default CoachAssignment;