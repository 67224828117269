import { FC, useState } from "react";
import { CheckIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import ProgressBar from "@atoms/ProgressBar";
import { ParsedStudentDataErrorReport } from "../types";
type UploadStatus = "idle" | number | "success" | "error";

type Props = {
    id: string;
    canUpload?: boolean;
    selectedSchoolYearId?: string;
    onFileSelect: (file: File) => Promise<ParsedStudentDataErrorReport>;
    onApplyChanges?: () => void;
    isLoading: boolean;
    error: string | null;
    actionPlan: any | null;
    hasRowsWithErrors: boolean;
}

function uploadInProgress(status: UploadStatus) {
    return status !== "idle" && status !== "success" && status !== "error";
}

const FileUpload: FC<Props> = ({
    id,
    canUpload,
    selectedSchoolYearId,
    onFileSelect,
    onApplyChanges,
    actionPlan,
    hasRowsWithErrors
}) => {
    const [status, setStatus] = useState<UploadStatus>("idle");
    const [file, setFile] = useState<File | null>(null);
    const [incompleteRows, setIncompleteRows] = useState<number[]>([]);
    const [schoolYearMismatchRows, setSchoolYearMismatchRows] = useState<number[]>([]);
    const [incorrectFields, setIncorrectFields] = useState<Record<number, string[]>>({});
    const [incorrectIdFormat, setIncorrectIdFormat] = useState<Record<number, string[]>>({});

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];

        if (!selectedFile) {
            setFile(null);
            setIncompleteRows([]);
            setSchoolYearMismatchRows([]);
            setIncorrectFields({});
            return;
        }

        setFile(selectedFile);

        const results = await onFileSelect(selectedFile);
        if (!results || results?.error) {
            setFile(null);
            setIncompleteRows([]);
            setSchoolYearMismatchRows([]);
            setIncorrectFields({});
            return;
        }

        // Check for school year mismatches if we have a selected school year
        if (selectedSchoolYearId) {
            const mismatchedRows = results.data
                .map((row, index) => row.school_year_id && row.school_year_id !== selectedSchoolYearId ? index : -1)
                .filter(index => index !== -1);
            setSchoolYearMismatchRows(mismatchedRows);
        }

        setIncompleteRows(results.incompleteRows);
        setIncorrectFields(results.incorrectFields || {});
        setIncorrectIdFormat(results.incorrectIdFormat || {});
    };

    const handleUpload = async () => {
        if (!file) return;

        if (incompleteRows.length > 0) {
            return;
        }

        if (schoolYearMismatchRows.length > 0) {
            return;
        }

        if (Object.keys(incorrectFields).length > 0) {
            return;
        }

        setStatus("idle");
        onApplyChanges?.();
    }

    const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
        // Clear the input value when the user clicks to choose a file
        e.currentTarget.value = '';
    };

    return (
        <div className="space-y-4">
            {status !== "success" &&
                <div className="flex flex-row gap-2">
                    <input
                        type="file"
                        accept=".csv"
                        disabled={uploadInProgress(status) || status === 'error'}
                        id={`file-upload-${id}`}
                        className="grow file:placeholder-current file:rounded-md file:border-none file:px-4 file:py-2 file:text-sm file:mr-4 file:bg-ponce file:text-white file:disabled:bg-ponce/50"
                        placeholder="Upload CSV file"
                        onChange={handleFileChange}
                        onClick={handleClick}
                    />
                    {!uploadInProgress(status) && (
                        <Button
                            className="block"
                            variant="once"
                            disabled={hasRowsWithErrors || !canUpload || !Boolean(file) || incompleteRows.length > 0 || schoolYearMismatchRows.length > 0 || Object.keys(incorrectFields).length > 0 || Object.keys(incorrectIdFormat).length > 0 || !actionPlan}
                            onClick={handleUpload}
                        >
                            Apply Changes
                        </Button>
                    )}
                    {uploadInProgress(status) && <ProgressBar current={status as number} total={100} />}
                </div>
            }
            {status === "success" && (
                <div className="text-green-500 italic flex flex-row gap-2 items-center">
                    <CheckIcon /> File uploaded successfully
                </div>
            )}
        </div>
    )
}

export default FileUpload;