import classNames from "classnames";
import useSubmissionFormModal from "./UseSubmissionFormModal";
import SubmissionForm from "./SubmissionForm";
import { useEffect, useRef } from "react";
import { useToast } from "../ui/use-toast";


const SubmissionModal = () => {
    const context = useSubmissionFormModal();
    const { toast } = useToast();
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const escHandler = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                context?.closeDialog(false);
            }
        }
        document.addEventListener("keydown", escHandler);
        return () => document.removeEventListener("keydown", escHandler);
    }, [context?.show]);

    useEffect(() => {
        function onClick(event: MouseEvent) {
            if (!context?.show) { return; }
            const clickedOutsideMainElement = containerRef.current && containerRef.current.contains(event?.target as any);
            const clickedOnContentElement = contentRef.current && contentRef.current.contains(event?.target as any);
            if (clickedOutsideMainElement && !clickedOnContentElement) {
                context?.closeDialog(false);
            }
        }
        document.addEventListener("mousedown", onClick);
        return () => document.removeEventListener("mousedown", onClick);
    }, [context?.show]);

    if (!context) {
        console.error("SubmissionModal must be used within a SubmissionFormProvider");
        return null;
    }

    const { show, closeDialog, studentId, administrationDay, cycle, videoDay, videoUrl, studentName, roomName, sessionId } = context;

    if (!show) {
        return null;
    }

    if (!studentId || !administrationDay || !cycle || !videoDay || !videoUrl || !studentName || !roomName || !sessionId) {
        console.log("Data is not correctly set", { studentId, administrationDay, cycle, videoDay, videoUrl, studentName, roomName, sessionId });
        toast({
            title: "Error",
            description: "Something went wrong. Please try again.",
        });
        return null;
    }


    return <div ref={containerRef} className={classNames("fixed inset-0 z-50 bg-black bg-opacity-35 w-screen h-screen")}>
        <div className="flex flex-col justify-center items-center h-full">
            <div ref={contentRef} className="bg-white p-8 border border-black rounded flex flex-col gap-4 aspect-video">
                <SubmissionForm
                    sessionId={sessionId}
                    roomName={roomName}
                    studentName={studentName}
                    studentId={studentId}
                    administrationDay={administrationDay}
                    cycle={cycle}
                    videoDay={videoDay}
                    videoUrl={videoUrl}
                    onSubmit={(data) => {
                        console.log("submission data", data);
                        closeDialog(false);
                        toast({
                            title: "Success",
                            description: "Assessment submitted successfully",
                            variant: "success",
                        });
                    }}
                    onError={(error) => {
                        closeDialog(false);
                        toast({
                            title: "Error",
                            description: error,
                            variant: "destructive",
                        });
                    }}
                />
            </div>
        </div>
    </div>;
};

export default SubmissionModal;
