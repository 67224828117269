import { Day } from "@/lib/core/day";
import { CELL_CLASSES, TODAY_CELL_CLASSES } from "./LogRow";
import cn from "classnames";

type Props = {
    rowTitle?: string,
    dates: Day[],
    holidays: Set<string>,
    log: { [date: string]: number },
    today: string
}


export function asPercentString(num: number): string {
    return (num * 100.0).toFixed(0) + "%";
}

const AttendanceRow: React.FC<Props> = ({ rowTitle, log, dates, holidays, today }) => {
    return (
        <tr className="text-sm italic">
            <td className={cn("p-1.5 LogCell overflow-hidden text-left pl-12",
                { border: Boolean(rowTitle) }
            )} colSpan={7}>{rowTitle}</td>
            {dates.map(date => {
                const ds = date.toString();
                const value = log && log[ds];

                return <td className={cn(
                    today === ds ? TODAY_CELL_CLASSES : CELL_CLASSES,
                    {
                        "bg-goodAverage": value && value >= 0.8
                    }
                )} key={ds}>
                    {(!holidays?.has(ds)) && (value ? asPercentString(value) : "0%")}
                </td>;
            })}
        </tr>
    );
};

export default AttendanceRow;