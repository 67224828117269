import { FC } from "react";
import { Select } from "@mantine/core";
import { useSchoolList } from "../hooks/useSchool";
import StepPara from "../components/StepPara";

type Props = {
    enabled: boolean;
    selectedYear: number | null;
    selectedSchool: string | null;
    onComplete: (school: string | null) => void;
}

const SelectSchool: FC<Props> = ({ enabled, onComplete, selectedYear, selectedSchool }) => {
    const { data: schools, isLoading, isError } = useSchoolList(selectedYear);

    return <>
        {isLoading && (
            <StepPara>
                Loading...
            </StepPara>
        )}
        {isError && (
            <StepPara>
                Error loading schools.
            </StepPara>
        )}
        {schools && (
            <>
                <StepPara>
                    Select the school you want to provision accounts for.
                </StepPara>
                <Select
                    label="Select School"
                    data={schools.map(school => ({ label: school.school_name, value: school.school_id.toString() }))}
                    value={selectedSchool}
                    onChange={(value) => onComplete(value ?? null)}
                    disabled={!enabled}
                />
            </>
        )}
    </>

}

export default SelectSchool;