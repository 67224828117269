import { authProvider } from "@/auth";
import { READING_ASSESSOR_DASHBOARD_ID } from "@/consts";
import ExploPage from "@organisms/ExploPage";
import { useFlags } from "launchdarkly-react-client-sdk";

const ReadingReport: React.FC = () => {
    const { readingAssessorTab } = useFlags();

    const embedId = READING_ASSESSOR_DASHBOARD_ID;
    const userCanViewReadingAssessorTab = (readingAssessorTab && authProvider.isAssessor()) || authProvider.isEmployee();

    if (!userCanViewReadingAssessorTab) {
        return <div>
            Unauthorized
        </div>
    }

    return (
        <ExploPage embedId={embedId} />
    )
};

export default ReadingReport;