import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import { SubmissionData, FormProps } from "./SubmissionForm";
import { useToast } from "@/components/ui/use-toast";

type Committer = (data: SubmissionData) => void;
type Data = Partial<FormProps> & Partial<SubmissionData> & { show: boolean, committer?: Committer };

export type SubmissionFormContextType = Data & {
    openDialog: (data: SubmissionData & { studentName: string }, committer: Committer) => void;
    closeDialog: (v: boolean) => void;
}

const SubmissionFormDialogContext = createContext<SubmissionFormContextType | null>(null);
export const SubmissionFormDialogProvider = SubmissionFormDialogContext.Provider;

function useSubmissionFormModal(): SubmissionFormContextType | null {
    const submissionFormDialogContext: SubmissionFormContextType | null = useContext(SubmissionFormDialogContext);

    return submissionFormDialogContext
}

export const SubmissionFormProvider: React.FC<PropsWithChildren<{ onSubmit: (data: SubmissionData & { studentName: string }) => void }>> = ({ children, onSubmit }) => {
    const [dialogData, setDialogData] = useState<Data>({ show: false });
    const { toast } = useToast();
    const openDialog = (data: SubmissionData & { studentName: string }, committer: Committer) => {
        console.log("openDialog", data.cycle);

        setDialogData({ show: true, ...data, committer });
    }

    const closeDialog = useCallback((v: boolean) => {
        if (!v) {
            setDialogData({ show: false, committer: undefined });
            return;
        }

        if (dialogData.committer && dialogData.studentId && dialogData.administrationDay && dialogData.cycle && dialogData.videoDay && dialogData.videoUrl && dialogData.studentName && dialogData.roomName && dialogData.sessionId) {
            onSubmit({
                sessionId: dialogData.sessionId,
                studentName: dialogData.studentName,
                roomName: dialogData.roomName,
                studentId: dialogData.studentId,
                administrationDay: dialogData.administrationDay,
                cycle: dialogData.cycle,
                videoDay: dialogData.videoDay,
                videoUrl: dialogData.videoUrl,
                valid: dialogData.valid ?? false,
                numberOfErrors: dialogData.numberOfErrors,
                totalWords: dialogData.totalWords,
                notes: dialogData.notes
            });
        } else {
            console.error("Instructor, committer or day is not set", dialogData);
            toast({
                title: "Error",
                description: "Instructor, committer or day is not set",
            });
        }
        setDialogData({ show: false, committer: undefined });
    }, [onSubmit, dialogData.committer, dialogData.studentId, dialogData.administrationDay, dialogData.cycle, dialogData.videoDay, dialogData.videoUrl, dialogData.roomName, dialogData.sessionId]);

    return (
        <SubmissionFormDialogProvider value={{ ...dialogData, openDialog, closeDialog }}>
            {children}
        </SubmissionFormDialogProvider>
    )

};

export default useSubmissionFormModal;