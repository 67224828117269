
import Select from 'react-select'
import useImpersonation, { BasicUserListEntry } from '@/hooks/useImpersonation'
import { Button } from '../ui/button';

const UserImpersonationSelect = (props: {
  users: BasicUserListEntry[],
  onChange: (cycle: string) => void,
  value: BasicUserListEntry | null,
}
) => {
  if (!props.users) {
    return null
  }


  const options = props.users?.map((user) => {
    return { value: user.user_id, label: `${user.name} (${user.email}) ${user.has_logged_in ? "" : "(never logged in)"}` }
  });

  if (!options) {
    return null
  }


  return <Select value={props.value ? {
    value: props.value.user_id,
    label: `${props.value.name} (${props.value.email}) ${props.value.has_logged_in ? "" : "(never logged in)"}`,
  } : {
    value: null,
    label: "Choose a user"
  }}
    options={options}
    styles={{
      control: (styles) => ({
        ...styles,
        width: 500,
      }),
      option: (styles) => ({
        ...styles,
        color: "black"
      })
    }}
    onChange={value => {
      if (!value?.value) return
      props.onChange(value.value)
    }}
  />
}

const UserImpersonationControl: React.FC = () => {
  const { userList, startImpersonating, stopImpersonating, impersonatedUser } = useImpersonation();

  if (impersonatedUser) {
    return <Button variant="secondary" size="sm" onClick={stopImpersonating}>Stop impersonating {impersonatedUser.name}</Button>
  }

  if (!userList) {
    return null;
  }

  return <UserImpersonationSelect
    users={userList ?? []}
    onChange={(userId) => {
      const user = userList.find((user) => user.user_id === userId);
      if (!user) return;
      startImpersonating?.(user)
    }}
    value={impersonatedUser}
  />
}
export default UserImpersonationControl