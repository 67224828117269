import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";
import { INSTRUCTIONAL_LOG_REFETCH_SETTINGS } from "@/consts";
import { Day } from "@/lib/core/day";

export type MaxCurriculum = {
    student_id: string;
    value: string;
    date: string;
    student_name: string;
}

export type MaxCurriculumOutput = Map<string, { value: string, day: Day, student_name: string }>;

const QUERY_KEY = ["max_curriculum"];

const convertToOutput = (data: MaxCurriculum[]): MaxCurriculumOutput => {
    if (data) {
        return new Map(data.map(({ student_id, date, ...remaining }) => {
            return [student_id, {
                ...remaining,
                day: Day.fromString(date)
            }];
        }));
    }
    return new Map()
}

const maxCurriculumFetch = async (school_id: string): Promise<MaxCurriculumOutput> => {
    const url = `/schools/${school_id}/max_curriculum`;
    const { data } = await authorizedBackendGet<{ curriculum: MaxCurriculum[] }>(url);
    return convertToOutput(data.curriculum)
};

export const useMaxCurriculum = (school_id: string) => {
    return useQuery<MaxCurriculumOutput, Error>({
        queryKey: ["instructional_log", school_id].concat(QUERY_KEY),
        queryFn: () => maxCurriculumFetch(school_id),
        notifyOnChangeProps: "all",
        ...INSTRUCTIONAL_LOG_REFETCH_SETTINGS
    });
};
