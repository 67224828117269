import { FC } from "react";
import StepPara from "../components/StepPara";
import FileUpload from "@/components/Admin/Provisioning/components/FileUpload";
import FilePreview from "../components/FilePreview";
import { useProvisioningWorkflow } from "../hooks/useProvisioningWorkflow";
type Props = {
    explanation: string | string[];
    enabled: boolean;
    schoolId: string | null;
    schoolYearId: number | null;
    onSuccess: () => void;
    onError: () => void;
}

const UploadStep: FC<Props> = ({ explanation, enabled, schoolId, schoolYearId, onSuccess, onError }) => {
    const {
        parseResults,
        actionPlan,
        isLoading,
        error,
        setError,
        validateFile,
        generateActionPlan,
        onApplyChanges,
        hasRowsWithErrors
    } = useProvisioningWorkflow({
        schoolId,
        year: schoolYearId
    });

    const applyChanges = () => {
        if (error) {
            return;
        }
        console.log("applyChanges", actionPlan);

        const errorHandler = (e: any) => {
            console.error("Error applying changes:", e);
            setError("The server is experiencing problems right now. Please reload and try again later.");
            onError();
        }
        onApplyChanges(onSuccess, errorHandler);
    }

    if (error) {
        return (
            <>
                <StepPara>{explanation}</StepPara>
                <div className="text-red-500">{error}</div>
            </>
        )
    }

    return (
        <>
            {Array.isArray(explanation) ? explanation.map((text, index) => <StepPara key={index}>{text}</StepPara>) : <StepPara>{explanation}</StepPara>}
            <FileUpload
                id="upload-step"
                canUpload={enabled}
                selectedSchoolYearId={schoolYearId?.toString()}
                onFileSelect={validateFile}
                onApplyChanges={applyChanges}
                isLoading={isLoading}
                error={error}
                actionPlan={actionPlan}
                hasRowsWithErrors={hasRowsWithErrors}
            />
            {parseResults && (
                <FilePreview
                    parseResults={parseResults}
                    actionPlan={actionPlan}
                    isLoading={isLoading}
                    error={error}
                    onGenerateActionPlan={generateActionPlan}
                />
            )}
        </>
    );
};

export default UploadStep;