import Notification, { NotificationKind } from "@atoms/Notification";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

const NOTIFICATION_KEY = "tryonce_notification";

type NotificationSpec = {
    kind: NotificationKind;
    title: string;
    description: string;
}

type NotificationContextType = {
    setNotification: (spec: NotificationSpec) => void;
}

const NotificationContext = createContext<NotificationContextType | null>(null);
const Provider = NotificationContext.Provider;

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [notification, setNotification] = useState<NotificationSpec | null>(null);

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === NOTIFICATION_KEY) {
                console.log("Notification changed", event.newValue);
                setNotification(JSON.parse(event.newValue || ''));
            }
        };

        window.addEventListener('storage', handleStorageChange);
        const handler = setTimeout(() => {
            setNotification(null);
        }, 10000);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearTimeout(handler);
        };
    }, [notification]);


    const addNotification = (spec: NotificationSpec) => {
        localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(spec));
        setNotification(spec);
    }

    const endNotification = () => {
        localStorage.removeItem(NOTIFICATION_KEY);
        setNotification(null);
    }

    return (
        <Provider value={{ setNotification: addNotification }}>
            {notification && <Notification kind={notification.kind} title={notification.title} onClose={endNotification}>
                {notification.description}
            </Notification>}
            {children}
        </Provider>
    );
}

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotification must be used within a NotificationProvider");
    }
    return context;
}