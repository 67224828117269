import { useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type Objective = {
  id: number;
  objective: string;
  is_active: boolean;
  is_onboarding: boolean;
};

export type AnsweredOnboardingObjective = {
  created_at: string;
  objective_id: number;
  objective_db_id: number;
};

const QUERY_KEY = ["Objectives"];

const fetchObjectives = async (): Promise<Objective[]> => {
  const { data } = await authorizedBackendGet<{ objectives: Objective[] }>("/objectivesMetadata");
  return data.objectives;
};

export const useObjectives = () => {
  return useQuery<Objective[], Error>({
    queryKey: QUERY_KEY,
    queryFn: fetchObjectives,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

const fetchAnsweredOnboardingObjectives = async (instructorId: string) => {
  const { data } = await authorizedBackendGet<{ answers: AnsweredOnboardingObjective[] }>(
    `/instructor/scoring/${instructorId}/onboarding`
  );
  return data.answers;
}


export const useAnsweredOnboardingObjectives = (instructorId: string | null) => {
  const queryKey = QUERY_KEY.concat("answered", instructorId?.toString() ?? "");
  const queryClient = useQueryClient();
  return {
    query: useQuery<AnsweredOnboardingObjective[], Error>({
      queryKey,
      queryFn: () => fetchAnsweredOnboardingObjectives(instructorId as string),
      staleTime: 1000 * 60 * 60, // 1 hour
      enabled: !!instructorId,
    }),
    invalidate: () => {
      queryClient.invalidateQueries({ queryKey });
    }
  };
};
