import { Suspense, useEffect } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes, redirect, useLocation, useNavigationType
} from "react-router-dom";
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { authProvider } from "./auth";
import Login from "./components/login";
import MagicLinked from "./components/magic_linked";
import Menu from "./components/menu";
import Session, { sessionAction, sessionLoader } from "./pages/session";
import StudentData from "./pages/student-data";

import './index.css';
import ErrorPage from "./components/error-page";
import SessionFinished from "./components/SessionFinished";
import MyRecordings from "./pages/my-recordings";
import Curriculum from "./pages/curriculum";
import InstructionalLog from "./pages/instructional-log";
import InstructorObjectives from "./pages/instructor-objectives";
import InstructorScoring from "./pages/instructor-scoring";
import CoachingMeeting from "./pages/coaching-meeting";
import { UserImpersonation } from "./hooks/useImpersonation";
import LoginNew from "./components/login-new";
import CoachMeetingReport from "./pages/coach-meeting-report";
import VacationDates from "./pages/admin/vacation-dates";
import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import UngatedInstructionalLog from "./pages/tab-7";
import CoachManagement from "./pages/coach-management";
import CurriculumDebug from "./pages/debug/curriculum";
import ReadingReport from "./pages/reading-report";
import ShowPermissions from "./pages/admin/show-permissions";
import RosterUpload from "./pages/roster-upload";
import AccountProvisioning from "./pages/admin/account-provisioning";
import InstructorObjectivesCombined from "./pages/instructor-objectives-combined";

Sentry.init({
  dsn: "https://d7a8d65ea550a44bd27f02bece80ecba@o4507172268867584.ingest.us.sentry.io/4507188034076672",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export enum SearchParams {
  Cycle = "cycle",
  StudentId = "student_id",
  StudentName = "student_name",
  JWT = "jwt",
  EnrollmentJwt = "enrollment_jwt",
  StytchUserId = "stytch_user_id",
  AttachToken = "attach_token",
  Email = "email",
}

export enum LocalStorageParams {
  Cycle = "CYCLE",
  StudentId = "STUDENT_ID",
  JWT = "JWT",
  SelectedSchool = "SELECTED_SCHOOL",
}

// If the user is not logged in and tries to access a protected endpoint, we redirect
// them to `/login
const searchParams = new URLSearchParams(location.search);

const Loader = () => <div>Loading</div>;
const routes: RouteObject[] = [
  {
    id: "root",
    path: "/",
    loader: protectedLoader,
    element: <Menu />,
    children: [
      {
        element: <Session />,
        errorElement: <ErrorPage />,
        loader: sessionLoader,
        action: sessionAction,
        shouldRevalidate: () => false,
        // If the user has a student id in the url, 
        // we make "/" the index route
        index: true,
      },
      {
        path: "/instructional-log",
        element: <InstructionalLog />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/tab7",
        element: <UngatedInstructionalLog />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/student-data",
        element: <StudentData />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/my-recordings",
        element: <MyRecordings />,
        errorElement: <ErrorPage />,
        shouldRevalidate: () => false
      },
      {
        path: "/meeting-report",
        element: <CoachMeetingReport />,
        errorElement: <ErrorPage />
      },
      {
        path: "/reading-report",
        element: <ReadingReport />,
        errorElement: <ErrorPage />
      },
      {
        path: "session-finished",
        Component: SessionFinished,
      },
      {
        path: "instructor-objectives",
        Component: InstructorObjectives,
      },
      {
        path: "curriculum",
        Component: Curriculum,
      },
      { path: "curriculum/:cycle", Component: Curriculum },
      {
        path: "instructor-scoring",
        Component: InstructorScoring,
      },
      { path: "coaching-meeting", Component: CoachingMeeting },
      { path: "admin/vacations", Component: VacationDates },
      { path: "coach-management", Component: CoachManagement },
      { path: "instructor-objectives-combined", Component: InstructorObjectivesCombined },
      { path: "debug-tools", Component: CurriculumDebug },
      { path: "admin/permissions", Component: ShowPermissions },
      { path: "account-provisioning", Component: AccountProvisioning },
      { path: "roster-upload", Component: RosterUpload },
    ]
  },
  {
    path: "login",
    Component: Login,
  },
  {
    path: "login-new",
    Component: LoginNew,
  },
  {
    path: "magic_linked",
    Component: MagicLinked,
  }
]

const router = sentryCreateBrowserRouter(routes);

/**
 * Returns a function that can be used as a loader for a route in the router.
 * This function ensures that the user is authenticated before rendering the route,
 * and if the user is not authenticated, it redirects them to the login page.
 */
async function protectedLoader() {
  await authProvider.signin();


  if (!authProvider.isAuthenticated) {
    return redirect("/login?" + searchParams.toString());
  }

  return authProvider;
}

const queryClient = new QueryClient();

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    ponce: ['#eaf2ff',
      '#d7e2f9',
      '#adc1ee',
      '#809fe3',
      '#5b82d9',
      '#446fd4',
      '#3666d3',
      '#2856bb',
      '#1f4ca8',
      '#0f4196']
  }
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID
  })

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <MantineProvider theme={theme}>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <UserImpersonation>
            <Suspense fallback={<Loader />}>
              <RouterProvider router={router} />
            </Suspense>
          </UserImpersonation>
        </QueryClientProvider>
      </LDProvider>
    </MantineProvider>
  );
})();
