import { FC, PropsWithChildren, useState } from "react";
import { Icons } from "@/components/icons";
import { authorizedBackendGet } from "@/lib/backend";
import { toast } from "../ui/use-toast";
type Props = {
    requestUrl: string;
}

const AuthorizedRedirect: FC<PropsWithChildren<Props>> = ({ children, requestUrl }) => {
    const [isLoading, setIsLoading] = useState(false);
    return <a className="flex flex-row items-center justify-center relative" href={requestUrl} onClick={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await authorizedBackendGet<{ url: string }>(requestUrl);
            if (response.status === 200) {
                setIsLoading(false);
                window.open(response.data.url, "_blank");
            } else {
                setIsLoading(false);
                toast({
                    variant: "destructive",
                    title: "Error redirecting to recording",
                    description: "Unable to redirect to recording. Please try again.",
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                variant: "destructive",
                title: "Error redirecting to recording",
                description: "Unable to redirect to recording. Please try again.",
            });
        }
        // If it returns a 200, then redirect to the url
        // Otherwise, show an error message
    }}><div className="">{children}</div>
        <div className="absolute left-2">{isLoading && <Icons.spinner className="animate-spin ml-auto mr-auto" />}</div></a>;
}

export default AuthorizedRedirect;