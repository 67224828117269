import { FC, useMemo } from "react";
import { Plan, StudentData } from "../types";

type Props = {
    value: string;
    header: keyof StudentData;
    plans: Plan<any>[];
}

const ActionPlanValue: FC<Props> = ({ value, plans, header }) => {
    const displayValue = useMemo(() => {
        if (value !== "") {
            return value;
        }

        const plan = plans.find((plan) => plan.data[header] !== undefined);
        if (plan) {
            const value = plan.data[header];
            return value;
        }
        return value;
    }, [value, plans, header]);

    return (
        <>
            {displayValue}
        </>
    )
}

export default ActionPlanValue