import { Permission } from "@/lib/authz/authz_generated";
import AuthGuard from "@molecules/AuthGuard";

const _InnerRosterUpload: React.FC = () => {
    return (
        <div className="m-4 flex flex-col gap-4 w-full h-full">
            <div className="text-center"><a className="underline" href="https://docs.google.com/spreadsheets/d/13Fh7JQ69TXY1VK1Dfd3rDsacAzvHOWy_/edit?gid=904754407#gid=904754407">Download Template Once Roster Document</a></div>
            <div className="w-full flex-grow min-h-full">
                <iframe className="airtable-embed" src="https://airtable.com/embed/appJ7eFIdrKCnj19b/pagURy9P74CJgm7g2/form"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    style={{
                        "background": "transparent",
                        "border": "1px solid #ccc;"
                    }}></iframe>
            </div>
        </div>
    )
};

const RosterUpload: React.FC = () => {
    return <AuthGuard requiredPermissions={[Permission.CanViewRosterUpload]}>
        <_InnerRosterUpload />
    </AuthGuard>
}

export default RosterUpload;