import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { authorizedBackendGet } from '@/lib/backend';

interface Permission {
    permission_id: number;
    permission_name: string;
    role_id: number;
    role_name: string;
}

async function getUserPermissions(): Promise<{ permissions: Permission[] }> {
    const response = await authorizedBackendGet("/permissions");
    return response.data as { permissions: Permission[] };
}

const ShowPermissions: React.FC = () => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ["permissions"],
        queryFn: () => getUserPermissions(),
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading permissions</div>;
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Permissions and Roles</h1>
            <table className="min-w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2">ID</th>
                        <th className="border border-gray-300 px-4 py-2  border-r-black">Permission</th>
                        <th className="border border-gray-300 px-4 py-2">ID</th>
                        <th className="border border-gray-300 px-4 py-2">Role</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.permissions.map((permission: Permission, index: number) => (
                        <tr key={index}>
                            <td className="border border-gray-300 px-4 py-2 italic text-gray-500">({permission.permission_id})</td>
                            <td className="border border-gray-300 px-4 py-2  border-r-black">
                                {permission.permission_name}
                            </td>
                            <td className="border border-gray-300 px-4 py-2 italic text-gray-500">({permission.role_id})</td>
                            <td className="border border-gray-300 px-4 py-2">
                                {permission.role_name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ShowPermissions;
