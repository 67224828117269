type Props = {
    legend: React.ReactNode;
    prevWeek: () => void;
    nextWeek: () => void;
}

const DateNavigator: React.FC<Props> = ({ prevWeek, nextWeek, legend }) => {
    return (
        <div className="flex flex-row gap-3 justify-between items-center text-2xl">
            <button className="text-4xl bold" onClick={prevWeek}>&larr;</button>
            <div className="text-lg grow">{legend}</div>
            <button className="text-4xl bold" onClick={nextWeek}>&rarr;</button>
        </div>
    )
}

export default DateNavigator;