import { Permission, Role } from "@/lib/authz/authz_generated";
import { authorizedBackendGet } from "./lib/backend";

export type AuthProvider = AuthUser & {
  signin(): Promise<void>;
  isCoach(): boolean;
  isEmployee(): boolean;
  isDistrictAdmin(): boolean;
  canAccessInstructionalLog(): boolean;
  isAssessor(): boolean;
  hasPermission(permission: Permission): boolean;
  hasRole(role: Role): boolean;
};

type AuthUser = {
  isAuthenticated: boolean;
  username: string | null;
  email: string | null;
  pictureUrl: string | null;
  instructionalLogUrl: string | null;
  studentDataEmbedId: string | null;
  permissions: Permission[];
  roles: Role[];
};

export const authProvider: AuthProvider = {
  isAuthenticated: false,
  username: null,
  pictureUrl: null,
  email: null,
  instructionalLogUrl: null,
  studentDataEmbedId: null,
  permissions: [],
  roles: [],
  isCoach: () => { return authProvider.hasRole(Role.Coach) || authProvider.hasRole(Role.Corporate) }, // TODO: Remove and use hasRole
  isEmployee: () => { return authProvider.hasRole(Role.Corporate) }, // TODO: Remove and use hasRole
  isAssessor: () => { return authProvider.hasRole(Role.Assessor) }, // TODO: Remove and use hasRole
  isDistrictAdmin: () => { return authProvider.hasRole(Role.Corporate) }, // TODO: Remove and use hasRole
  canAccessInstructionalLog: () => { return authProvider.hasPermission(Permission.CanViewInstructionalLog) || authProvider.hasPermission(Permission.CanWriteInstructionalLog) },
  hasPermission: (permission: Permission) => { return authProvider.permissions.includes(permission) },
  hasRole: (role: Role) => { return authProvider.roles.includes(role) },
  async signin() {
    let result = new Promise<AuthUser>(function (resolve) {
      if (localStorage.getItem("JWT") == null) {
        console.log("No JWT found");
        resolve({
          isAuthenticated: false,
          username: null,
          email: null,
          pictureUrl: null,
          instructionalLogUrl: null,
          studentDataEmbedId: null,
          permissions: [],
          roles: [],
        });
      } else {
        console.log("JWT found");
        authorizedBackendGet<{
          name?: string;
          picture?: string;
          email?: string;
          instructional_log?: string;
          student_data_embed_id?: string;
          permissions?: Permission[];
          roles?: Role[];
        }>("/me")
          .then((res) => {
            resolve({
              isAuthenticated: true,
              username: res.data.name || null,
              pictureUrl: res.data.picture || null,
              email: res.data.email || null,
              instructionalLogUrl: res.data.instructional_log || null,
              studentDataEmbedId: res.data.student_data_embed_id || null,
              permissions: res.data.permissions || [],
              roles: res.data.roles || [],
            });
          })
          .catch((err) => {
            console.log("Error while checking jwt: " + err);
            localStorage.removeItem("JWT");
            resolve({
              isAuthenticated: false,
              username: null,
              pictureUrl: null,
              email: null,
              instructionalLogUrl: null,
              studentDataEmbedId: null,
              permissions: [],
              roles: [],
            });
          });
      }
    });
    let credentials: AuthUser = await result;
    authProvider.isAuthenticated = credentials.isAuthenticated;
    authProvider.username = credentials.username;
    authProvider.pictureUrl = credentials.pictureUrl;
    authProvider.email = credentials.email;
    authProvider.instructionalLogUrl = credentials.instructionalLogUrl;
    authProvider.studentDataEmbedId = credentials.studentDataEmbedId;
    authProvider.permissions = credentials.permissions;
    authProvider.roles = credentials.roles;
  },
};
