import cn from "classnames";
import { CircleX, TriangleAlert, UserPlus, UserPen, SchoolIcon } from "lucide-react";
import { Plan } from "../types";
const ActionPlanIcons = ({ rowNumber, incompleteRows, incorrectFields, plans }: { rowNumber: number, incompleteRows: number[], incorrectFields: Record<number, string[]>, plans: Plan<any>[] }) => {
    const classes = "h-4 w-4";
    const icons = {
        incomplete: <CircleX className={cn(classes, "text-red-500")} />,
        error: <CircleX className={cn(classes, "text-red-500")} />,
        incorrect: <TriangleAlert className={cn(classes, "text-yellow-500")} />,
        create: <UserPlus className={cn(classes, "text-green-500")} />,
        update: <UserPen className={cn(classes, "text-blue-500")} />,
        createAssociation: <SchoolIcon className={cn(classes, "text-green-500")} />
    }

    const errors = plans.filter((plan: Plan<any>) => (plan.errors?.length ?? 0) > 0);

    if (errors.length > 0) {
        return (
            <>
                {icons.error}
            </>
        )
    }

    return (
        <>
            {incompleteRows.includes(rowNumber) && (
                icons.incomplete
            )}
            {(incorrectFields[rowNumber]?.length ?? 0) > 0 && (
                icons.incorrect
            )}
            {plans.some((plan: Plan<any>) => plan.action === "create") && (
                icons.create
            )}
            {plans.some((plan: Plan<any>) => plan.action === "update" && (plan.modified_fields?.length ?? 0) > 0) && (
                icons.update
            )}
            {plans.some((plan: Plan<any>) => plan.action === "create-association") && (
                icons.createAssociation
            )}
        </>
    )
}

export default ActionPlanIcons;