import { SchoolEntry, SchoolListOutput } from "@/hooks/useSchoolsList";
import Select from 'react-select'

type Props = {
    schools: SchoolListOutput | undefined;
    selectedSchool: string | null;
    setSelectedSchool: (school: string) => void;
}

const toOption = (school: SchoolEntry) => ({
    value: school.school_id,
    label: `${school.district_name} - ${school.school_short_name}`
});

const commonClasses = "flex flex-col";

const SchoolSelection: React.FC<Props> = ({ schools, selectedSchool, setSelectedSchool }) => {
    const selectedOption = selectedSchool ? schools?.schools.get(selectedSchool) : undefined;
    if (!schools) {
        return null;
    }

    const options = Array.from(schools.schools.values()).map(toOption);

    if (!options) {
        return null;
    }

    return (
        <div className={commonClasses}>
            <Select value={selectedOption ? toOption(selectedOption) : undefined}
                options={options}
                onChange={(value) => {
                    if (!value?.value) return;
                    setSelectedSchool(value.value);
                }}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        width: 500,
                    }),
                    menu: (styles) => ({
                        ...styles,
                        zIndex: 1000,
                    }),
                }}
            />
        </div>
    )
}

export default SchoolSelection;