import { Cross2Icon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { ParsedStudentDataErrorReport, StudentData, Plan, ActionPlan } from "../types";
import Papa from 'papaparse';
import { ParseResult } from 'papaparse';
import { FC, useEffect, useMemo } from "react";
import { CheckCircleIcon, Loader2 } from "lucide-react";
import ActionPlanIcons from "./ActionPlanIcons";
import ActionPlanValue from "./ActionPlanValue";
import cn from "classnames";

const allowedMissingFields = new Set([
    "student_id",
    "school_id",
    "school_association_id",
    "start_date",
    "end_date"
]);
const idPrefixRequiredFields = new Set([
    "student_id",
    "school_id",
    "school_association_id"
]);

const headerRowNames: Record<keyof StudentData, string> = {
    student_id: "Once Student ID",
    local_student_id: "Local Student ID",
    first_name: "First Name",
    last_name: "Last Name",
    grade_level: "Grade",
    school_id: "Once School ID",
    school_association_id: "DNC",
    start_date: "Start Date",
    end_date: "End Date",
    school_year_id: "School Year"
};

const fieldsToHighlight = new Set([
    'student_id',
    'local_student_id',
    'first_name',
    'last_name',
    "school_association_id",
    "school_year_id",
    "grade_level",
    "school_id",
    "start_date",
    "end_date"
]);

export const parseStudentFile = (file: File, schoolId: string, schoolYearId: string): Promise<ParsedStudentDataErrorReport> => {
    return new Promise((resolve) => {
        if (file.type !== 'text/csv') {
            resolve({
                data: [],
                incompleteRows: [],
                missingFields: {},
                incorrectFields: {},
                incorrectIdFormat: {},
                error: 'Please upload a CSV file'
            });
            return;
        }

        Papa.parse(file, {
            complete: (results: ParseResult<StudentData>) => {
                const data = results.data;
                const headers = Object.keys(data[0] || {}) as (keyof StudentData)[];
                const incomplete: number[] = [];
                const missing: Record<number, string[]> = {};
                const incorrect: Record<number, string[]> = {};
                const incorrectIdFormat: Record<number, string[]> = {};
                data.forEach((row, index) => {
                    const missingFields = headers.filter(header => {
                        if (allowedMissingFields.has(header)) {
                            return false;
                        }
                        return !row[header] || row[header]?.trim() === '';
                    });
                    if (missingFields.length > 0) {
                        incomplete.push(index);
                        missing[index] = missingFields;
                    }

                    const missingPrefixFields = headers.filter(header => {
                        if (!idPrefixRequiredFields.has(header)) {
                            return false;
                        }

                        // If the field is missing, we don't check for the id prefix
                        const value = row[header];
                        if (!value) {
                            return false;
                        }

                        return !value.toString().startsWith('id-');
                    })
                    if (missingPrefixFields.length > 0) {
                        incorrectIdFormat[index] = missingPrefixFields;
                    }

                    if (row.school_year_id !== "" && row.school_year_id !== schoolYearId) {
                        incorrect[index] = ['school_year_id'];
                        console.log("school_year_id", row.school_year_id, schoolYearId);
                    }
                    if (row.school_id === "") {
                        row.school_id = schoolId;
                    } else if (row.school_id !== "" && row.school_id !== schoolId) {
                        incorrect[index] = ['school_id'];
                        console.log("school_id", row.school_id, schoolId);
                    }
                });

                resolve({
                    data,
                    incompleteRows: incomplete,
                    missingFields: missing,
                    incorrectFields: incorrect,
                    incorrectIdFormat,
                    error: null
                });
            },
            error: (error: Error) => {
                resolve({
                    data: [],
                    incompleteRows: [],
                    missingFields: {},
                    incorrectFields: {},
                    incorrectIdFormat: {},
                    error: 'Error parsing CSV file: ' + error.message
                });
            },
            header: true,
            skipEmptyLines: true
        });
    });
};

export const formatValue = (value: any, header: keyof StudentData, plans?: Plan<any>[]) => {
    if (header === 'student_id' && plans?.length) {
        const studentPlan = plans.find(plan => 'student_id' in plan.data);
        if (studentPlan?.data.student_id) {
            return studentPlan.data.student_id.toString().replace(/^id-/, '');
        }
    }
    if (header === 'school_association_id' && plans?.length) {
        const associationPlan = plans.find(plan => 'school_association_id' in plan.data);
        if (associationPlan?.data.school_association_id) {
            return associationPlan.data.school_association_id.toString().replace(/^id-/, '');
        }
    }

    if (!value) return value;
    if (header.toLowerCase().includes('id')) {
        return value.toString().replace(/^id-/, '');
    }
    return value;
};

type Props = {
    parseResults: ParsedStudentDataErrorReport;
    actionPlan: ActionPlan | null;
    isLoading: boolean;
    error: string | null;
    onGenerateActionPlan: (data: StudentData[]) => void;
};

const FilePreview: FC<Props> = ({ parseResults, actionPlan, isLoading, error, onGenerateActionPlan }) => {
    const { data, incompleteRows, missingFields, incorrectFields, incorrectIdFormat } = parseResults;

    const actionPlanRowNumberMap = useMemo(() => {
        if (!actionPlan) return new Map();

        const rowMap = new Map<number, Plan<any>[]>();

        // Process student plans
        Object.values(actionPlan.students ?? {}).forEach(plan => {
            const rowNumber = plan.data._row_number;
            if (rowNumber !== undefined) {
                if (!rowMap.has(rowNumber)) {
                    rowMap.set(rowNumber, []);
                }
                rowMap.get(rowNumber)?.push(plan);
            }
        });

        // Process association plans
        Object.values(actionPlan.associations ?? {}).forEach(plan => {
            const rowNumber = plan.data._row_number;
            if (rowNumber !== undefined) {
                if (!rowMap.has(rowNumber)) {
                    rowMap.set(rowNumber, []);
                }
                rowMap.get(rowNumber)?.push(plan);
            }
        });

        return rowMap;
    }, [actionPlan]);

    const hasChanges = useMemo(() => {
        return Object.values(actionPlan?.students ?? {}).some(plan => (plan.modified_fields?.length ?? 0) > 0) ||
            Object.values(actionPlan?.associations ?? {}).some(plan => (plan.modified_fields?.length ?? 0) > 0);
    }, [actionPlan]);

    useEffect(() => {
        // Only generate action plan if we don't have one and the data is valid
        if (!actionPlan &&
            data.length > 0 &&
            incompleteRows.length === 0 &&
            Object.keys(incorrectFields).length === 0) {
            onGenerateActionPlan(data);
        }
    }, [data, incompleteRows, incorrectFields, onGenerateActionPlan, actionPlan]);

    const getCellClassName = (rowIndex: number, header: string) => {
        const baseClasses = "p-2 border-b max-w-[200px] break-words";
        const plans = actionPlanRowNumberMap.get(rowIndex) ?? [];
        const errors = plans.filter((plan: Plan<any>) => (plan.errors?.length ?? 0) > 0);

        if (errors.length > 0) {
            return `${baseClasses}`;
        }

        if (missingFields[rowIndex]?.includes(header)) {
            return `${baseClasses} bg-red-100`;
        }
        if (incorrectFields[rowIndex]?.includes(header)) {
            return `${baseClasses} bg-yellow-100`;
        }
        // Collect all modified fields from all update plans
        const modifiedFields = plans
            .filter((plan: Plan<any>) => plan.action === "update")
            .flatMap((plan: Plan<any>) => plan.modified_fields ?? []);

        if (modifiedFields.includes(header)) {
            return `${baseClasses} bg-blue-100`;
        }
        // Check if any plan for this row is a create action
        if (plans.some((plan: Plan<any>) => plan.action === "create") && fieldsToHighlight.has(header)) {
            return `${baseClasses} bg-green-100`;
        }

        if (plans.some((plan: Plan<any>) => plan.action === "create-association" && fieldsToHighlight.has(header))) {
            return `${baseClasses} bg-green-100`;
        }
        return baseClasses;
    };

    if (!data) {
        return null;
    }

    return (
        <div className="mt-4 relative">
            {isLoading && (
                <div className="absolute inset-0 bg-white/80 flex items-center justify-center z-10">
                    <div className="flex flex-col items-center gap-2">
                        <Loader2 className="h-8 w-8 animate-spin text-ponce" />
                        <p className="text-sm text-gray-600">Processing student data...</p>
                    </div>
                </div>
            )}
            {error && (
                <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
                    <div className="flex items-center gap-2 text-red-600">
                        <ExclamationTriangleIcon className="h-5 w-5" />
                        <p className="text-sm">{error}</p>
                    </div>
                </div>
            )}
            <h3 className="text-sm font-medium mb-2">
                Preview ({data.length} rows)
                {!hasChanges && incompleteRows.length === 0 && Object.keys(incorrectFields).length === 0 && error === null && (
                    <span className="text-green-500 flex items-center">
                        <CheckCircleIcon className="inline mr-1" />
                        <p className="text-sm text-gray-600">All rows are up to date, no changes needed.</p>
                    </span>

                )}
                {(incompleteRows.length > 0 || Object.keys(incorrectFields).length > 0) && (
                    <div className="space-y-1">

                        {incompleteRows.length > 0 && (
                            <span className="text-red-500 flex items-center">
                                <Cross2Icon className="inline mr-1" />
                                {incompleteRows.length} incomplete rows
                            </span>
                        )}
                        {Object.keys(incorrectFields).length > 0 && (
                            <span className="text-yellow-600 flex items-center">
                                <ExclamationTriangleIcon className="inline mr-1" />
                                {Object.values(incorrectFields).filter(fields => fields.includes('school_year_id')).length > 0 && (
                                    <span className="mr-2">
                                        {Object.values(incorrectFields).filter(fields => fields.includes('school_year_id')).length} incorrect school year
                                    </span>
                                )}
                                {Object.values(incorrectFields).filter(fields => fields.includes('school_id')).length > 0 && (
                                    <span>
                                        {Object.values(incorrectFields).filter(fields => fields.includes('school_id')).length} incorrect school ID
                                    </span>
                                )}
                            </span>
                        )}
                    </div>
                )}
            </h3>


            <div className="border rounded-md p-2">
                <table className="w-full text-xs">
                    <thead>
                        <tr>
                            <th className="w-6 p-2 border-b"></th>
                            {Object.keys(data[0] || {}).filter(header => header !== '_row_number').map((header) => (
                                <th key={header} className="text-left p-2 border-b max-w-[200px] truncate">{headerRowNames[header as keyof StudentData] ?? header}</th>
                            ))}
                            <th className="w-6 p-2 border-b">Problems</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, i) => {
                            const plans = actionPlanRowNumberMap.get(i) ?? [];
                            return <DataRow
                                key={`${row.student_id}-${i}`}
                                rowNumber={i}
                                classNames={(header: string) => getCellClassName(i, header)}
                                row={row}
                                plans={plans}
                                incompleteRows={incompleteRows}
                                incorrectFields={incorrectFields}
                                incorrectIdFormat={incorrectIdFormat}
                            />
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const DataRow: FC<{
    rowNumber: number;
    classNames: (header: string) => string;
    row: StudentData;
    plans: Plan<any>[];
    incompleteRows: number[];
    incorrectFields: Record<number, string[]>;
    incorrectIdFormat: Record<number, string[]>;
}> = ({
    row,
    plans,
    incompleteRows,
    incorrectFields,
    incorrectIdFormat,
    rowNumber,
    classNames,
}) => {
        return (
            <tr key={`${row.student_id}-${rowNumber}`} className={cn({
                "bg-red-100": plans.some((plan: Plan<any>) => (plan.errors?.length ?? 0) > 0),
                "bg-red-50": incompleteRows.includes(rowNumber),
            })}>
                <td className="p-2 border-b justify-center text-center">
                    <ActionPlanIcons rowNumber={rowNumber} incompleteRows={incompleteRows} incorrectFields={incorrectFields} plans={plans} />
                </td>
                {Object.entries(row)
                    .filter(([header]) => header !== '_row_number')
                    .map(([header, value], j) => (
                        <td
                            key={`${row.student_id}-${header}-${j}`}
                            className={classNames(header)}
                        >
                            <ActionPlanValue value={formatValue(value, header as keyof StudentData, plans)} plans={plans} header={header as keyof StudentData} />
                        </td>
                    ))}
                <td className="p-2 border-b">
                    {plans.filter((plan: Plan<any>) => (plan.errors?.length ?? 0) > 0).map((plan: Plan<any>, i) => (
                        <p key={`${row.student_id}-${plan.data._row_number}-${i}`}>{plan.errors?.join(', ')}</p>
                    ))}
                    {incorrectIdFormat[rowNumber]?.map((header, i) => (
                        <p key={`${row.student_id}-${header}-${rowNumber}-${i}`}>Edit the CSV and add "id-" before the {header} value.</p>
                    ))}
                </td>
            </tr>
        );
    }

export default FilePreview;