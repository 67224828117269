import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { SubmissionData } from "./SubmissionForm";
import { authorizedBackendGet, authorizedBackendPost } from '@/lib/backend';
import { AxiosError } from "axios";

const QUERY_KEY = ["assessmentData"];

const backendToFrontend = (data: any): SubmissionData => {
    return {
        sessionId: data.session_id,
        studentId: data.student_id,
        administrationDay: data.administration_date,
        cycle: data.cycle_assessed,
        videoDay: data.video_date,
        videoUrl: data.video_url,
        roomName: data.room_name,
        numberOfErrors: data.student_result,
        totalWords: data.student_result_possible,
        valid: data.is_valid,
    };
}

const getAssessmentData = async (studentId: string, sessionId: number) => {
    const url = `/students/${studentId}/reading-assessment/${sessionId} `;
    return await authorizedBackendGet<SubmissionData>(url).then((data) => backendToFrontend(data.data));
}

const upsertMutation = async (data: SubmissionData) => {
    const url = `/students/${data.studentId}/reading-assessment/${data.sessionId}`;
    return await authorizedBackendPost<SubmissionData>(url, data).then((data) => backendToFrontend(data.data));
}

const useAssessmentData = (studentId: string, sessionId: number, onError?: (error: string) => void) => {
    const queryClient = useQueryClient();
    const query = useQuery({
        queryKey: QUERY_KEY.concat(`${studentId}`, `${sessionId}`),
        queryFn: () => getAssessmentData(studentId, sessionId),
    });

    const upsertAssessmentData = useMutation({
        mutationKey: QUERY_KEY.concat("upsert", `${studentId}`, `${sessionId}`),
        mutationFn: upsertMutation,
        onMutate: async (newEntry: SubmissionData) => {
            const key = QUERY_KEY.concat(`${studentId}`, `${sessionId}`);
            await queryClient.cancelQueries({ queryKey: key });
            const previousEntries = queryClient.getQueryData<SubmissionData>(key);
            queryClient.setQueryData<SubmissionData>(key, (_old?: SubmissionData) => {
                return newEntry;
            });


            return { previousEntries }

        },
        onError: (error, _newEntry, context) => {
            console.error("onError", error);
            queryClient.setQueryData<SubmissionData>(QUERY_KEY.concat(`${studentId}`, `${sessionId}`), context?.previousEntries);
            if (error instanceof AxiosError) {
                onError?.(error.response?.data.error);
            } else {
                onError?.(error.message);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY.concat(`${studentId}`, `${sessionId}`) });
        }
    });

    return { query, upsertAssessmentData };
};

export default useAssessmentData;
