function changeFocus(row: number, col: number) {
    const element: any = document.querySelector(`input[data-row="${row}"][data-col="${col}"]`);
    if (element) {
        element.focus();
        element.setSelectionRange(0, element.value.length);
        element.select();
        return element;
    }

    return null;
}

function downHelper(currentRow: number, currentCol: number) {
    const nextRow = currentRow + 1;
    const downElement = changeFocus(nextRow, currentCol);
    // Normal Advance
    if (downElement) {
        return true;
    }

    // End of Instructor
    const nextThousand = Math.floor(currentRow / 1000 + 1) * 1000;
    const nextElement = changeFocus(nextThousand, currentCol);
    if (nextElement) {
        return true;
    }
    return false;
}

export function advanceCell(e: React.KeyboardEvent<HTMLInputElement>, colCount: number) {
    const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
    const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;

    if (downHelper(currentRow, currentCol)) {
        e.preventDefault();
        return;
    }

    // End of Column

    // We have to cycle through the columns to find the first one that isn't a holiday
    for (let nextCol = currentCol + 1; nextCol < colCount; nextCol++) {
        const nextElement = changeFocus(0, nextCol);
        if (nextElement) {
            e.preventDefault();
            return;
        }
    }
}

export function moveDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
    const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;
    if (downHelper(currentRow, currentCol)) {
        e.preventDefault();
        return;
    }
}

export function moveUp(e: React.KeyboardEvent<HTMLInputElement>) {
    const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
    const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;
    const previousRow = currentRow - 1;
    const upElement = changeFocus(previousRow, currentCol);
    if (upElement) {
        e.preventDefault();
        return;
    }

    // Beginning of Instructor
    const previousThousand = Math.floor(currentRow / 1000 - 1) * 1000;
    // Find the last row in this thousand block
    const currentThousand = Math.floor(currentRow / 1000) * 1000;
    for (let row = currentThousand - 1; row >= previousThousand; row--) {
        const element = changeFocus(row, currentCol);
        if (element) {
            e.preventDefault();
            return;
        }
    }
}

export function moveRight(e: React.KeyboardEvent<HTMLInputElement>, colCount: number) {
    const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
    const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;
    // We have to cycle through the columns to find the first one that isn't a holiday
    for (let nextCol = currentCol + 1; nextCol < colCount; nextCol++) {
        const nextElement = changeFocus(currentRow, nextCol);
        if (nextElement) {
            e.preventDefault();
            return;
        }
    }
}

export function moveLeft(e: React.KeyboardEvent<HTMLInputElement>) {
    const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
    const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;
    for (let nextCol = currentCol - 1; nextCol >= 0; nextCol--) {
        const nextElement = changeFocus(currentRow, nextCol);
        if (nextElement) {
            e.preventDefault();
            return;
        }
    }
}
