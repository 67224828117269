import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedBackendGet, authorizedBackendPost } from "@/lib/backend";
import { Day } from "@/lib/core/day";
import { INSTRUCTIONAL_LOG_REFETCH_SETTINGS } from "@/consts";

export type InstructionalLogEntry = {
    date: Day;
    value: string;
    comment?: string;
    student_id: string;
    instructional_log_entry_id?: string;
}


type WireFormat = {
    entries: InstructionalLogEntry[],
    writeable?: boolean,
};

export type EntryMap = Map<string, InstructionalLogEntry>;

export type InstructionalLogOutput = {
    entries: Map<string, EntryMap>
    writeable?: boolean;
}

export type SetInstructorStatus = {
    date: Day;
    instructor: string;
    value: string;
}


export const INSTRUCTIONAL_LOG_QUERY_KEY = ["instructional_log"];

const convertToOutput = (entries: WireFormat): InstructionalLogOutput => {
    const entriesMap: Map<string, EntryMap> = new Map();
    entries.entries.forEach(entry => {
        if (!entriesMap.has(entry.student_id)) {
            entriesMap.set(entry.student_id, new Map());
        }
        const ds = entry.date.toString();
        entriesMap.get(entry.student_id)?.set(ds, entry);
    });

    return { entries: entriesMap, writeable: entries.writeable };
}

const instructionalLog = async (school_id: string, date: Date): Promise<InstructionalLogOutput> => {
    const url = `/logs/instructional/${school_id}/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const { data } = await authorizedBackendGet<WireFormat>(url);
    const results = convertToOutput(data)
    return results;
};

export const useInstructionalLog = (school_id: string, date: Date) => {
    const queryClient = useQueryClient()

    const query = useQuery<InstructionalLogOutput, Error>({
        queryKey: INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]),
        queryFn: () => instructionalLog(school_id, date),
        notifyOnChangeProps: "all",
        ...INSTRUCTIONAL_LOG_REFETCH_SETTINGS
    });

    const upsert = useMutation({
        mutationKey: ["upsert_instructional_log", school_id, date.toDateString()],
        mutationFn: async (entry: InstructionalLogEntry) => {
            // TODO: Refactor: This comes in as a string for some reason
            const date = Day.fromString(entry.date as unknown as string);
            const url = `/logs/instructional/${school_id}/${date.year}/${date.month}/${date.day}`;
            return await authorizedBackendPost<InstructionalLogEntry>(url, entry);
        },
        onMutate: async (newEntry: InstructionalLogEntry) => {
            await queryClient.cancelQueries({ queryKey: INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]) });
            const previousEntries = queryClient.getQueryData<InstructionalLogOutput>(INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]));
            queryClient.setQueryData<InstructionalLogOutput>(INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]), (old?: InstructionalLogOutput) => {
                if (!old) {
                    return { entries: new Map([[newEntry.student_id, new Map([[newEntry.date.toString(), newEntry]])]]) };
                }
                const newEntries = new Map(old.entries);
                if (!newEntries.has(newEntry.student_id)) {
                    newEntries.set(newEntry.student_id, new Map());
                }
                newEntries.get(newEntry.student_id)?.set(newEntry.date.toString(), newEntry);
                return { entries: newEntries, writeable: old.writeable };
            });

            return { previousEntries };
        },
        onSettled: async () => {
            return await queryClient.invalidateQueries({
                queryKey: INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id])
            });
        },
        onError: (error, _newEntry, context) => {
            console.error("Error", error);
            queryClient.setQueryData<InstructionalLogOutput>(INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]), context?.previousEntries);
        }
    });

    const updateInstructorStatusForDay = useMutation({
        mutationKey: ["update_instructor_status", school_id, date.toDateString()],
        mutationFn: async (entry: SetInstructorStatus) => {
            const url = `/logs/instructional/${school_id}/instructor/${entry.instructor}/${entry.date.year}/${entry.date.month}/${entry.date.day + 1}`;
            return await authorizedBackendPost<SetInstructorStatus>(url, entry);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()])
            });
        },
        onSuccess: async (data, _variables) => {
            await queryClient.cancelQueries({ queryKey: INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]) });
            queryClient.setQueryData(INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]), (old?: InstructionalLogOutput) => {
                const newEntries = new Map(old?.entries);
                data.data.entries.forEach((value: any) => {
                    if (!newEntries.has(value.student_id)) {
                        newEntries.set(value.student_id, new Map());
                    }
                    // Normalize the date string
                    const date = new Date(value.date);
                    // Javascript is the worst. For whatever reason, even though literally the date is correct when coming in, the moment
                    // that javarscript touches it, it's not a day before. So we have to add a day to it to get the correct date.
                    date.setDate(date.getDate() + 1);

                    const day = Day.fromDate(date);
                    const date_string = day.toString();
                    newEntries.get(value.student_id)?.set(date_string, value);
                });

                return { entries: newEntries, writeable: old?.writeable ?? false };
            });


            return await queryClient.resetQueries({
                queryKey: INSTRUCTIONAL_LOG_QUERY_KEY.concat([school_id, date.toDateString()]),
            });
        },
        onError: (error, _newEntry, _context) => {
            console.error("Error", error);
        }
    });

    return { query, upsert, updateInstructorStatusForDay };
};