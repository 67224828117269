import { useMemo, useState } from 'react';
import { ActionPlan, ParsedStudentDataErrorReport, StudentData } from '../types';
import { parseStudentFile } from '../components/FilePreview';
import { authorizedBackendPost } from '@/lib/backend';

interface UseProvisioningWorkflowProps {
    schoolId: string | null;
    year: number | null;
}

export function useProvisioningWorkflow({ schoolId, year }: UseProvisioningWorkflowProps) {
    const [parseResults, setParseResults] = useState<ParsedStudentDataErrorReport | null>(null);
    const [actionPlan, setActionPlan] = useState<ActionPlan | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const validateFile = async (file: File) => {
        if (!schoolId || !year) {
            return {
                data: [],
                incompleteRows: [],
                missingFields: {},
                incorrectFields: {},
                incorrectIdFormat: {},
                error: "School ID and School Year ID are required"
            };
        }
        setActionPlan(null);
        setError(null);

        const results = await parseStudentFile(file, `id-${schoolId}`, `${year}`);
        setParseResults(results);
        return results;
    };

    const hasRowsWithErrors = useMemo(() => {
        if (!actionPlan) return false;
        return Object.values(actionPlan.students ?? {}).some(plan => (plan.errors?.length ?? 0) > 0) ||
            Object.values(actionPlan.associations ?? {}).some(plan => (plan.errors?.length ?? 0) > 0);
    }, [actionPlan]);

    const generateActionPlan = async (data: StudentData[]) => {
        if (!schoolId || !year) return;

        setIsLoading(true);
        setError(null);

        try {
            const dataWithRowNumbers = data.map((row, index) => ({
                ...row,
                _row_number: index
            }));

            const response = await authorizedBackendPost<any>(
                `/admin/provisioning/action-plan/${schoolId}/${year}`,
                { rows: dataWithRowNumbers }
            );

            if (response.status === 200) {
                setActionPlan(response.data.plan);
            } else {
                setError("The server is experiencing problems right now. Please try again later.");
            }
        } catch (error) {
            console.error('Error generating action plan:', error);
            setError("The server is experiencing problems right now. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const onApplyChanges = async (onSuccess: () => void, onError: (e: any) => void) => {
        if (!actionPlan) return;

        if (Object.keys(actionPlan).length === 0) {
            onSuccess();
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await authorizedBackendPost<any>(
                `/admin/provisioning/apply-changes/${schoolId}/${year}`,
                { plan: actionPlan }
            );
            if (response.status === 200) {
                setActionPlan(null);
                setParseResults(null);
                onSuccess();
            } else {
                setError("The server is experiencing problems right now. Please try again later.");
                onError(response.data);
            }
        } catch (error) {
            console.error('Error applying changes:', error);
            setError("The server is experiencing problems right now. Please try again later.");
            onError(error);
        } finally {
            setIsLoading(false);
        }


    }

    return {
        parseResults,
        actionPlan,
        isLoading,
        error,
        setError,
        validateFile,
        generateActionPlan,
        onApplyChanges,
        hasRowsWithErrors
    };
} 