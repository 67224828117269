import { authProvider } from "@/auth";
import Log from "@/components/InstructionalLog";
import { nextWeekday } from "@/components/InstructionalLog/utils";
import { Permission } from "@/lib/authz/authz_generated";
import { useCallback, useState } from "react";
import { useLocation } from "react-router";

function InstructionalLog() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const schoolId = searchParams.get("school_id");
  const [selectedSchool, _setSelectedSchool] = useState<string | null>(schoolId);
  const [startDate, setStartDate] = useState(nextWeekday(new Date()));

  const setDate = useCallback((date: Date) => {
    const adjustedDate = nextWeekday(date);
    setStartDate(adjustedDate);
  }, []);

  if (!authProvider.instructionalLogUrl && !authProvider.canAccessInstructionalLog()) {
    return (
      <div className="flex w-full justify-center p-20">
        <p>
          <strong>Oops!</strong> There is no instructional log associated with
          this user. If you believe this is an error, please email Once support
          at support@tryonce.com.
        </p>
      </div>
    );
  }

  if (!authProvider.hasPermission(Permission.UsesNativeInstructionalLog)) {
    return (
      <div className="flex w-full justify-center p-20">
        <p>
          <a
            href={authProvider.instructionalLogUrl!}
            target="_blank"
            className="text-blue-700 underline text-lg"
          >
            Click here to open your Instructional Log.
          </a>
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full p-3 gap-6">
      <Log selectedDate={startDate} school={selectedSchool} setDate={setDate} />
    </div>
  )
}

export default InstructionalLog;
