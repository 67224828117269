import { FC, useMemo, useState } from "react"
import { Timeline } from "@mantine/core"
import { DownloadIcon, CheckIcon, Pencil1Icon, UploadIcon, CalendarIcon, HomeIcon } from "@radix-ui/react-icons"
import SelectYear from "./Steps/SelectYear";
import SelectSchool from "./Steps/SelectSchool";
import DownloadCSV from "./Steps/DownloadCSV";
import BasicStep from "./Steps/BasicStep";
import UploadStep from "./Steps/UploadStep";
import { useSchoolList } from "./hooks/useSchool";
import { NotificationKind } from "@atoms/Notification";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@/hooks/useNotification";
interface Props {
    completed: string[];
    setCompleted: (value: string) => void;
    resetCompleted: (value: string) => void;
    onDownload: (year: number, schoolId: string, filename: string) => void;
    onUpdate: () => void;
    onUpload: () => void;
}

const UploadStepProcess: FC<Props> = ({ completed, setCompleted, resetCompleted, onDownload, onUpdate }) => {
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [selectedSchool, setSelectedSchool] = useState<string | null>(null);
    const { data: schools } = useSchoolList(selectedYear);
    const completedSet = new Set(completed);
    const { setNotification } = useNotification();
    const navigate = useNavigate();
    const whichStepActive = useMemo(() => {
        if (!Boolean(selectedYear)) return 0;
        if (!Boolean(selectedSchool)) return 1;

        return completedSet.size;
    }, [completedSet, selectedYear, selectedSchool]);

    const isCompleted = (step: string, previous?: string) => !completedSet.has(step) && (previous ? completedSet.has(previous) : true);

    return (
        <Timeline active={whichStepActive} bulletSize={30} lineWidth={2}>
            <Timeline.Item title="Select School Year" bullet={Boolean(selectedYear) && isCompleted("school_year") ? <CheckIcon /> : <CalendarIcon />}>
                <SelectYear onComplete={(year) => {
                    setSelectedYear(year);
                    resetCompleted("school_year");
                }} selectedYear={selectedYear} />
            </Timeline.Item>
            <Timeline.Item title="Select School" bullet={Boolean(selectedYear) && Boolean(selectedSchool) && isCompleted("school", "school_year") ? <CheckIcon /> : <HomeIcon />}>
                <SelectSchool enabled={isCompleted("school", "school_year") || !completedSet.has("download")} onComplete={(school) => {
                    setSelectedSchool(school);
                    setCompleted("school");
                }} selectedSchool={selectedSchool} selectedYear={selectedYear} />
            </Timeline.Item>

            <Timeline.Item title="Download Current Student CSV File" bullet={isCompleted("download", "school") ? <CheckIcon /> : <DownloadIcon />}>
                <DownloadCSV
                    enabled={isCompleted("download", "school")}
                    selectedYear={selectedYear!}
                    selectedSchool={selectedSchool!}
                    onComplete={() => {
                        const school = schools?.find((school) => school.school_id === selectedSchool);
                        const now = new Date().toISOString().replace(/[Z]/g, "").replace(/[\.:-]/g, '_');
                        const filename = `students_${school?.school_name} - ${now} - ${school?.district_name}.csv`;
                        setCompleted("download");
                        onDownload(selectedYear!, selectedSchool!, filename);
                    }}
                />
            </Timeline.Item>
            <Timeline.Item title="Update Student CSV File" bullet={isCompleted("update", "download") ? <CheckIcon /> : <Pencil1Icon />}>
                <BasicStep
                    explanation={[
                        "Use excel or google sheets to edit the file. Do not delete any columns.",
                        "If you delete a row, that row will not be updated, but the student will not be deactivated. If you want to deactivate a student, just set their end date to yesterday.",
                        "If you add a row for a student and do not include a start date for the student, the start date will be set as the date you upload the file or the school's start date, whichever is later.",
                        "If you add a row for a student and do not include an end date for the student, the end date will be set as the school's last day of school.",
                        "If the school_association_id column is included, do not change this value. This value is automatically generated by the system."
                    ]}
                    isCompleted={isCompleted("update", "download")}
                    onComplete={() => {
                        setCompleted("update");
                        onUpdate();
                    }}
                    buttonText="I have updated and saved the file as a CSV file"
                />
            </Timeline.Item>
            <Timeline.Item title="Upload Student CSV File" bullet={isCompleted("upload", "update") ? <CheckIcon /> : <UploadIcon />}>
                <UploadStep
                    schoolId={selectedSchool}
                    schoolYearId={selectedYear}
                    explanation={[
                        "Upload the student CSV file. After upload you will see a preview below that highlights any errors.",
                        "If there are no errors and the preview looks good, press \"Apply Changes\".",
                        "If there are errors, please fix the errors in the csv, click \"Choose File\" to upload again and see a new preview."
                    ]}
                    enabled={isCompleted("upload", "update")}
                    onSuccess={() => {
                        console.log("Upload successful");
                        setNotification({
                            kind: NotificationKind.Success,
                            title: "Upload Successful",
                            description: "The student CSV file has been uploaded successfully."
                        });
                        navigate(`/instructional-log?school_id=${selectedSchool}`);
                    }}
                    onError={() => {
                        console.log("Upload error");
                        // setCompleted("upload");
                    }}
                />
            </Timeline.Item>
            {/* <Timeline.Item title="DEBUG">
                <pre>{JSON.stringify([...completedSet], null, 2)}</pre>
                <p className="text-sm text-muted-foreground my-4 max-w-prose">
                    {selectedYear} - {selectedSchool}
                </p>
            </Timeline.Item> */}
        </Timeline>
    )
}

export default UploadStepProcess;