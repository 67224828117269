import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList
} from "@/components/ui/navigation-menu";
import { useLocation, useNavigate, useNavigation } from "react-router";
import { Outlet, useLoaderData } from 'react-router-dom';
import { Toaster } from "@/components/ui/toaster"
import { AuthProvider } from "@/auth";
import Nav from "./Nav";
import Dialog, { openDialog } from "./Dialog";
import { TRY_ONCE_SUPPORT_EMAIL } from "@/consts";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import UnderstoodAI from "./UnderstoodAI";
import UserImpersonationControl from "./Impersonation/UserImpersonationControl";
import { NotificationProvider } from "@/hooks/useNotification";

function Menu() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const navigation = useNavigation();
  const isNavigating = Boolean(navigation.location);

  const user = useLoaderData() as AuthProvider;

  // Since authProvider isn't always inside of a React component, we can't set the LaunchDarkly identity in the authProvider.
  // Also since the app isn't wrapped in a Top Level `App` Component, we can't do it there either, so we do it here
  // in the menu since it guarantees that it'll be setup.
  const ldClient = useLDClient();
  const roles = user.roles.map((role) => role.toLowerCase());
  useEffect(() => {
    ldClient?.identify({
      kind: "user",
      key: user.email ?? undefined,
      name: user.username ?? undefined,
      email: user.email,
      roles
    })
  }, [ldClient, user.email, user.username]);

  let location = useLocation();
  if (location.pathname === "/login") {
    return null
  }

  const handleLogout = () => {
    localStorage.removeItem('JWT')
    localStorage.removeItem('impersonation')
    return navigate("/login?" + searchParams.toString())
  }

  return (
    <div className="h-screen flex flex-col">
      <div className=" bg-ponce text-white">
        <div className="flex h-16 items-center px-4">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem className="flex items-center gap-4">
                <img src={"/logo.png"} width={100} />
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>

          <div className="flex flex-grow flex-row items-center justify-center">
            <UserImpersonationControl />
          </div>
          <UnderstoodAI />


          <div className="flex items-center space-x-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                  <Avatar className="h-8 w-8 text-black">
                    {user.pictureUrl && <AvatarImage src={user.pictureUrl} />}
                    <AvatarFallback delayMs={200}>{user.username?.slice(0, 2)}</AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">{user.email}</p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem >
                  <button onClick={() => {
                    openDialog("contact-dialog");
                  }}>Contact us</button>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <a href="https://curriculum.tryonce.com/" target="_blank" rel="noreferrer">Curriculum Guide</a>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <a href="https://docs.tryonce.com/" target="_blank" rel="noreferrer">Implementation Guide</a>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleLogout()}>
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div className="flex flex-1 ">
        <Dialog id="contact-dialog" title="Contact Us">
          <p className="text-gray-500">
            Email our support team at <a href={`mailto:${TRY_ONCE_SUPPORT_EMAIL}`} className="text-blue-500">{TRY_ONCE_SUPPORT_EMAIL}</a>
          </p>
        </Dialog>
        <Nav />
        {isNavigating && <div className="absolute inset-0 bg-black/50 z-50" />}
        <NotificationProvider>
          <Outlet />
        </NotificationProvider>
      </div>
      <Toaster />

    </div>
  );
}

export default Menu;
