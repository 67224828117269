import { CellContext, Column, ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";
import { ArrowUpDown, MonitorPlay, NotebookPen } from "lucide-react";
import {
  Recording as WireRecording,
  useRecordings,
} from "@/hooks/useRecordings";
import { Icons } from "@/components/icons";
import useSubmissionFormModal, { SubmissionFormProvider } from "@/components/ReadingAssessments/UseSubmissionFormModal";
import { Day } from "@/lib/core/day";
import SubmissionModal from "@/components/ReadingAssessments/SubmissionModal";
import { useFlags } from "launchdarkly-react-client-sdk"
import { useMemo } from "react";
import AuthorizedRedirect from "@/components/_atoms/AuthorizedRedirect";
import { authProvider } from "@/auth";
import { Permission } from "@/lib/authz/authz_generated";
// We need to convert some columns from what's sent over the wire from the server.
// For example, timestamp is converted from an iso8601 string to a Date object.
type Recording = WireRecording & {
  timestamp: Date;
  useRedirect: boolean;
};

function transformWireIntoRecording(recording: WireRecording): Recording {
  let timeStr = recording.timestamp;
  if (!timeStr) {
    return recording as Recording;
  }

  // For some reason, the iso8601 strings from the backend do not have the timezone modifier on the end
  // We know these are UTC times, so we are manually adding the Z to indicate UTC
  if (!timeStr.endsWith("Z")) {
    timeStr = timeStr + "Z";
  }

  const utcDate = new Date(timeStr);

  return {
    ...recording,
    timestamp: utcDate,
    useRedirect: false,
  } as Recording;
}

const columnIds = [
  "student_name",
  "timestamp",
  "cycle_number",
  "instructor_name",
  "school_name",
  "district_name",
];

const colIdToLabelMap: { [colname: string]: string } = {
  student_name: "Student",
  timestamp: "Date",
  cycle_number: "Cycle",
  instructor_name: "Instructor",
  school_name: "School",
  district_name: "District",
};

const columnDef: ColumnDef<Recording>[] = [
  {
    accessorKey: "recording_url",
    header: "Link to recording",
    cell: (row) => {
      if (row.row.original.useRedirect) {
        return <AuthorizedRedirect requestUrl={row.getValue() as string}><MonitorPlay className="ml-auto mr-auto" /></AuthorizedRedirect>
      }
      return <a href={row.getValue() as string} target="blank" ><MonitorPlay className="ml-auto mr-auto" /></a>
    }
  },
  {
    accessorKey: "assessment_button",
    header: "Submit Assessment",
    cell: (row) => {
      if (!authProvider.hasPermission(Permission.CanWriteReadingAssessment)) {
        return null;
      }
      const context = useSubmissionFormModal();

      if (!context) {
        console.error("Submission Form Dialog must be used within a SubmissionFormDialogProvider");
        return null;
      }
      const { openDialog } = context;
      const truncatedCycle = row.row.original.cycle_number.split(".")[0];
      const cycleNumber = parseInt(truncatedCycle);
      return <Button
        variant="link"
        onClick={() => {
          openDialog({
            sessionId: row.row.original.id,
            studentName: row.row.original.student_name ?? "",
            studentId: row.row.original.student_id,
            administrationDay: Day.today(),
            cycle: cycleNumber,
            videoDay: row.row.original.timestamp ? Day.fromDate(row.row.original.timestamp) : Day.today(),
            videoUrl: row.row.original.recording_url ?? "",
            roomName: row.row.original.room_name ?? ""
          }, (data) => {
            // Need data to come across
            console.log("submission data", data);
          });
        }}
      >
        <NotebookPen className="ml-auto mr-auto" />
      </Button>
    }
  },
  ...columnIds.map((col) => {
    if (col === "timestamp") {
      return {
        accessorKey: col,
        header: ({ column }: { column: Column<Recording, unknown> }) => (
          <SortableColumnHeader column={column} />
        ),
        // Render the timestamp as a date in the local timezone
        cell: (row: CellContext<Recording, unknown>) => {
          const utcDate = row.getValue() as Date | undefined;
          const localDateStr = utcDate?.toLocaleDateString();
          return localDateStr;
        },
        // To filter, do a string match, but always fail if there is no date.
        filterFn: (row, columnId, filterValue: string) => {
          const utcDate = row.getValue(columnId) as Date | undefined;
          const localDateStr = utcDate?.toLocaleDateString();
          return localDateStr ? localDateStr.includes(filterValue) : false;
        },
      } as ColumnDef<Recording, unknown>;
    }
    return {
      accessorKey: col,
      header: ({ column }: { column: Column<Recording, unknown> }) => (
        <SortableColumnHeader column={column} />
      ),
    };
  }),
];

const filters = columnIds.map((id) => {
  return {
    id,
    label: colIdToLabel(id)
  }
})

export default function MyRecordings() {
  const { useCloudStorage } = useFlags();
  const { data, isLoading } = useRecordings();
  const finalData = useMemo(() => {
    if (!data) {
      return [];
    }
    const transformed = data.map<Recording>((recording) => transformWireIntoRecording(recording));
    if (useCloudStorage) {
      return transformed.map<Recording>((recording) => {
        if (recording.recording_url) {
          const redirectUrl = `/session/${recording.id}/video`;
          return {
            ...recording,
            recording_url: redirectUrl,
            useRedirect: true
          }
        }
        return recording;
      })
    }
    return transformed;
  }, [data, useCloudStorage]);
  const processedColumnDef = useMemo(() => {
    if (!authProvider.hasPermission(Permission.CanWriteReadingAssessment)) {
      return columnDef.filter((col: any) => col.accessorKey !== "assessment_button");
    }
    return columnDef;
  }, [columnDef]);

  if (isLoading || !data) {
    return <div className="m-auto"><Icons.spinner className="animate-spin ml-auto mr-auto mt-48" /></div>
  }



  return (
    <div className="p-4 w-full">
      <SubmissionFormProvider onSubmit={() => { }}>
        <SubmissionModal />
        <DataTable
          columns={processedColumnDef}
          data={finalData.filter((a) => !!a.district_name)}
          filters={filters}
        />
      </SubmissionFormProvider>
    </div>
  )
}

export function SortableColumnHeader({ column }: {
  column: Column<Recording, unknown>
}) {
  return <Button
    variant="ghost"
    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  >
    {colIdToLabel(column.id)}
    <ArrowUpDown className="ml-2 h-4 w-4" />
  </Button>
}

function colIdToLabel(id: string) {
  const mapped = colIdToLabelMap[id];
  if (mapped) return mapped;

  return id.split("_").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")
}
