export const TRY_ONCE_SUPPORT_EMAIL = "support@tryonce.com";
export const DEFAULT_CYCLE = "1.0";

export const INSTRUCTIONAL_LOG_REFETCH_SETTINGS = {
    staleTime: 1000 * 60, // 1 minute,
    refetchInterval: 1000 * 60 * 1, // 1 minute
    refetchIntervalInBackground: false, // Don't refresh if the tab is in the background
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
};

export const COACH_MEETING_REPORT_DASHBOARD_ID_COACH = "P8xK5NLAoZ";
export const COACH_MEETING_REPORT_DASHBOARD_ID_EMPLOYEE = "P8xK5NLAoZ";
export const READING_ASSESSOR_DASHBOARD_ID = "9PY4BR71M4";